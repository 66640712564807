import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "w-full bg-blue-600 text-center text-base font-semibold text-white py-3"
}
const _hoisted_3 = { class: "flex items-center brand-logo-container justify-between w-full md:w-auto" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "w-full bg-blue-600 text-center text-base font-semibold text-white py-3"
}
const _hoisted_7 = {
  key: 0,
  class: "flex items-center brand-logo-container justify-between w-full md:w-auto"
}
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "mr-2 flex items-center md:hidden" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex items-center text-primary-600 pt-1.5" }
const _hoisted_12 = {
  key: 2,
  class: "hidden md:flex justify-end items-center md:ml-10 md:pr-4 col-start-3"
}
const _hoisted_13 = { class: "flex items-center cursor-pointer" }
const _hoisted_14 = {
  id: "settings",
  class: "ml-2 relative",
  "x-data": "{ open: false }"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = ["aria-label"]
const _hoisted_17 = { class: "py-1 rounded-md bg-white shadow-xs" }
const _hoisted_18 = {
  key: 0,
  id: "mobile-settings-menu",
  class: "z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
}
const _hoisted_19 = { class: "rounded-lg shadow-md" }
const _hoisted_20 = { class: "rounded-lg bg-white shadow-xs overflow-hidden" }
const _hoisted_21 = { class: "px-5 pt-4 flex items-center justify-between" }
const _hoisted_22 = { class: "mobile-brand-logo-container" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 1,
  class: "font-semibold"
}
const _hoisted_25 = { class: "-mr-2" }
const _hoisted_26 = { class: "px-2 pt-2 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Home05Icon = _resolveComponent("Home05Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_UIContentWrap = _resolveComponent("UIContentWrap")!
  const _component_MobileTopBarNavigationV2 = _resolveComponent("MobileTopBarNavigationV2")!
  const _component_BookOpen01Icon = _resolveComponent("BookOpen01Icon")!
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_UITextMdMedium = _resolveComponent("UITextMdMedium")!
  const _component_DotsGridIcon = _resolveComponent("DotsGridIcon")!
  const _component_PWAAlertModal = _resolveComponent("PWAAlertModal")!

  return (_ctx.isPortalActive && !_ctx.newMobileScreens && _ctx.source !== _ctx.SOURCES.communities)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isAdmin && !_ctx.isBuilder)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t('common.adminMode')), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_UIContentWrap, null, {
          default: _withCtx(() => [
            (_ctx.isPortalActive && !_ctx.newMobileScreens)
              ? (_openBlock(), _createBlock(_component_NavBar, {
                  key: 0,
                  routes: _ctx.routes,
                  isHome: true,
                  onToggle: _ctx.handleToggle,
                  onLogout: _ctx.signOut,
                  backgroundImage: 
          _ctx.isBackgroundColor(_ctx.headerSectionStyling)
            ? ''
            : _ctx.replaceBucketUrlWithCdnUrl(_ctx.headerSectionStyling.background)
        ,
                  position: _ctx.getNavbarAlignment,
                  opacityImg: 
          _ctx.headerSectionStyling.opacity ? _ctx.headerSectionStyling.opacity : ''
        ,
                  class: _normalizeClass({ main: true }),
                  style: _normalizeStyle({
          'background-color': _ctx.isBackgroundColor(_ctx.headerSectionStyling)
            ? _ctx.headerSectionStyling.background
            : '',
          'box-shadow': '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
          position: 'absolute',
          top: _ctx.isAdmin && !_ctx.isBuilder ? '48px' : '',
        })
                }, {
                  "filter-left": _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      (_ctx.showLogo)
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 0,
                            id: "brandLogo",
                            to: { name: _ctx.libraryPath }
                          }, {
                            default: _withCtx(() => [
                              (_ctx.brandLogoUrl)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "brand-logo h-6 w-auto sm:h-6",
                                    src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.brandLogoUrl),
                                    alt: _ctx.t('common.logo.alt')
                                  }, null, 8, _hoisted_4))
                                : (_openBlock(), _createBlock(_component_Home05Icon, {
                                    key: 1,
                                    class: "h-5 w-5 font-semibold"
                                  }))
                            ]),
                            _: 1
                          }, 8, ["to"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  "filter-right": _withCtx(() => [
                    _createElementVNode("div", {
                      class: "flex items-center cursor-pointer",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
                    }, [
                      _createVNode(_component_UIButton, {
                        class: "h-10 w-10",
                        id: "search-icon",
                        quaternary: true,
                        circle: true,
                        "aria-label": _ctx.t('navigation.search.ariaLabel')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BaseIcon, {
                            name: "search",
                            hwClass: "h-4 w-4 text-gray-500"
                          })
                        ]),
                        _: 1
                      }, 8, ["aria-label"])
                    ])
                  ]),
                  _: 1
                }, 8, ["routes", "onToggle", "onLogout", "backgroundImage", "position", "opacityImg", "style"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass({
      'sticky top-0 z-20 min-h-12': _ctx.newMobileScreens,
    })
      }, [
        (_ctx.newMobileScreens && _ctx.isPortalActive && !_ctx.isBuilder)
          ? (_openBlock(), _createBlock(_component_MobileTopBarNavigationV2, {
              key: 0,
              backCallback: _ctx.backCallback
            }, null, 8, ["backCallback"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_ctx.isAdmin && !_ctx.isBuilder)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.t('common.adminMode')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                id: "navigation-header",
                class: _normalizeClass([[_ctx.getThemeClasses(_ctx.headerRoot, _ctx.routeName), 'py-1'], "px-4 sm:px-6 lg:px-8 shadow bg-white"])
              }, [
                _createElementVNode("nav", {
                  class: _normalizeClass(["flex md:grid md:grid-cols-3 items-center py-2", [
            `justify-${_ctx.alignment}`,
            _ctx.getThemeClasses(_ctx.headerContainer, _ctx.routeName),
          ]])
                }, [
                  (_ctx.source !== _ctx.SOURCES.communities)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_ctx.showLogo)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              id: "brandLogo",
                              to: { name: _ctx.libraryPath }
                            }, {
                              default: _withCtx(() => [
                                (_ctx.brandLogoUrl)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      class: "brand-logo h-6 w-auto sm:h-6",
                                      src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.brandLogoUrl),
                                      alt: _ctx.t('common.logo.alt')
                                    }, null, 8, _hoisted_8))
                                  : (_openBlock(), _createBlock(_component_Home05Icon, {
                                      key: 1,
                                      class: "h-5 w-5 font-semibold"
                                    }))
                              ]),
                              _: 1
                            }, 8, ["to"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", {
                            class: "flex items-center mr-2 md:mr-0",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSearchDrawer && _ctx.handleSearchDrawer(...args)))
                          }, [
                            _createVNode(_component_BaseIcon, {
                              name: "search",
                              hwClass: "w-4 h-4"
                            })
                          ]),
                          (_ctx.isPostDetailsPath || _ctx.isAssignmentOrQuizPath)
                            ? (_openBlock(), _createBlock(_component_BookOpen01Icon, {
                                key: 0,
                                class: "curriculum-drawer h-5 w-5 mx-2 clickable",
                                onClick: _ctx.handleOpenCurriculumComments
                              }, null, 8, ["onClick"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("button", {
                            id: "openMobileMenu",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open = true)),
                            type: "button",
                            class: "inline-flex items-center justify-center rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          }, [
                            _createVNode(_component_BaseIcon, {
                              name: "hamburger",
                              hwClass: "w-6 h-6 md:w-3 md:h-3"
                            })
                          ])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_UIButton, {
                          id: "back__btn",
                          text: true,
                          onClick: _ctx.onClickBack
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_ChevronLeftIcon, { class: "h-6 w-6 text-black" }),
                              _createVNode(_component_UITextMdMedium, { class: "text-black" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('common.back')), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])),
                  (_ctx.source !== _ctx.SOURCES.communities)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (!_ctx.newMobileScreens && _ctx.isPortalActive)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              id: "library",
                              to: { name: _ctx.libraryPath },
                              class: "font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_13, [
                                  _createVNode(_component_UIButton, {
                                    class: "h-10 w-10",
                                    id: "search-icon",
                                    quaternary: true,
                                    circle: true
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_DotsGridIcon, { class: "h-5 w-5" })
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            }, 8, ["to"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", {
                          class: "flex items-center cursor-pointer",
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
                        }, [
                          _createVNode(_component_UIButton, {
                            class: "h-10 w-10",
                            id: "search-icon",
                            quaternary: true,
                            circle: true,
                            "aria-label": _ctx.t('navigation.search.ariaLabel')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_BaseIcon, {
                                name: "search",
                                hwClass: "w-4 h-4"
                              })
                            ]),
                            _: 1
                          }, 8, ["aria-label"])
                        ]),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", null, [
                            _createElementVNode("button", {
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openSettings = !_ctx.openSettings)),
                              class: "flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out"
                            }, [
                              _createElementVNode("img", {
                                class: "aspect-square object-cover w-8 rounded-full transition duration-150 ease-in-out hover:shadow-md hover:ring-2 hover:ring-nova-blue-600",
                                src: _ctx.userImage,
                                alt: "Instructor"
                              }, null, 8, _hoisted_15)
                            ])
                          ]),
                          (_ctx.openSettings)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                id: "settings-menu",
                                class: "origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg",
                                "aria-label": _ctx.t('navigation.profile.menu')
                              }, [
                                _createElementVNode("div", _hoisted_17, [
                                  (!_ctx.isAdmin)
                                    ? (_openBlock(), _createBlock(_component_router_link, {
                                        key: 0,
                                        to: { name: 'profile-settings' },
                                        class: "block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('navigation.profile.settings')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.redirectTo('settings/profile'))),
                                        class: "cursor-pointer hover:underline px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                      }, _toDisplayString(_ctx.t('navigation.profile.settings')), 1)),
                                  (
                      !_ctx.isAdmin && _ctx.pwaAppSettings.pwaEnabled && !_ctx.isPWAInstalled
                    )
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 2,
                                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.triggerPWAEvent && _ctx.triggerPWAEvent(...args))),
                                        class: "block w-full text-left font-semibold px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-nova-blue-600 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                      }, _toDisplayString(_ctx.t('navigation.installApp')), 1))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("a", {
                                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args))),
                                    href: "#",
                                    class: "block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                                  }, _toDisplayString(_ctx.t('navigation.logout')), 1)
                                ])
                              ], 8, _hoisted_16))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2),
                (_ctx.open)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              (_ctx.brandLogoUrl)
                                ? (_openBlock(), _createElementBlock("img", {
                                    key: 0,
                                    class: "brand-logo h-10 w-auto",
                                    src: _ctx.replaceBucketUrlWithCdnUrl(_ctx.brandLogoUrl),
                                    alt: "brand-logo"
                                  }, null, 8, _hoisted_23))
                                : (_openBlock(), _createElementBlock("p", _hoisted_24, "Your Logo"))
                            ]),
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("button", {
                                id: "closeMobileMenu",
                                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open = false)),
                                type: "button",
                                class: "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                              }, [
                                _createVNode(_component_BaseIcon, { name: "cross" })
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createVNode(_component_router_link, {
                              to: { name: _ctx.libraryPath },
                              class: "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('navigation.library')), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            (!_ctx.isAdmin)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  to: { name: 'profile-settings' },
                                  class: "mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.t('navigation.profile.settings')), 1)
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("a", {
                              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args))),
                              href: "#",
                              class: "block w-full px-5 py-3 text-base font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out"
                            }, _toDisplayString(_ctx.t('navigation.logout')), 1)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_PWAAlertModal, {
                  open: _ctx.showPWAAlert,
                  onClose: _ctx.closePWAAlert
                }, null, 8, ["open", "onClose"])
              ], 2)
            ]))
      ], 2))
}