import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  UIModal,
  UIModalHeader,
  UIButton,
  UIModalContent,
  UIDisplaySmMedium,
  UITextXsMedium,
} from '@gohighlevel/ghl-ui'
import CouponForm from '@/components/checkout/CouponForm.vue'
import { useI18n } from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'CouponFormModal',
  props: {
  isCouponModalActive: {
    type: Boolean,
    default: false,
  },
  offer: {
    type: Object,
    default: () => ({}),
  },
  appliedCouponData: {
    type: Object,
    default: null,
  },
  localCouponData: {
    type: Object,
    default: null,
  },
  currency: {
    type: String,
    default: '',
  },
},
  emits: ['toggleCouponModal', 'updateCouponData'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()



const emit = __emit

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UIModal), {
    show: __props.isCouponModalActive,
    width: 400
  }, {
    header: _withCtx(() => [
      _createVNode(_unref(UIModalHeader), {
        type: "primary",
        id: "coupon-modal-header",
        icon: null,
        title: _unref(t)('checkout.coupon.modal.title'),
        onClose: _cache[0] || (_cache[0] = () => emit('toggleCouponModal'))
      }, null, 8, ["title"])
    ]),
    default: _withCtx(() => [
      _createVNode(_unref(UIModalContent), null, {
        default: _withCtx(() => [
          _createVNode(_unref(UITextXsMedium), { class: "amount-paid-text text-gray-700 font-family-inter" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('checkout.label.total')), 1)
            ]),
            _: 1
          }),
          _createVNode(_unref(UIDisplaySmMedium), { class: "price-text text-gray-900 font-family-inter" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`${__props.currency}${
            __props.localCouponData ? __props.localCouponData?.total : __props.offer.amount
          }`), 1)
            ]),
            _: 1
          }),
          _createVNode(CouponForm, {
            class: "w-full mt-4 bg-white rounded-lg",
            offer: __props.offer,
            borders: false,
            showLabel: false,
            appliedData: __props.appliedCouponData,
            onUpdateCouponData: _cache[1] || (_cache[1] = (data) => emit('updateCouponData', data))
          }, null, 8, ["offer", "appliedData"])
        ]),
        _: 1
      }),
      _createVNode(_unref(UIButton), {
        onClick: _cache[2] || (_cache[2] = () => emit('toggleCouponModal')),
        id: "coupon-checkout",
        type: "primary",
        class: "w-full"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('common.button.proceed')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})