import '@gohighlevel/ghl-ui/dist/style.css'
import '@gohighlevel/clientportal-core/dist/style.css'
import '@/assets/css/tailwind.css'
import '@/assets/css/tokens.css'
import './style.css'
import '@/assets/css/postContent.css'

import { SwipeDirective, initializeApp } from '@gohighlevel/clientportal-core'
import { VueHeadMixin, createHead } from '@unhead/vue'
import {
  fetchCurrencySymbol,
  replaceBucketUrlWithCdnUrl,
  truncateText,
} from '../src/helper/filter'

import App from './App.vue'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'
import config from './config'
import { createApp } from 'vue'
import { fetchCat } from '@/helper/permission.helper'
import firebase from 'firebase/app'
import highlight from '@/directives/v-highlight'
import { isClientPortal } from './helper'
import mitt from 'mitt'
import router from './router'
import { setupSentry } from './plugins/setupSentry'
import store from './store'
import { i18n } from './i18n'

const emitter = mitt()
declare const ga: any
;(async () => {
  if (isClientPortal()) await initializeApp(process.env.NODE_ENV, router)
  firebase.initializeApp(config.firebase)

  const app = createApp(App)
  app.config.warnHandler = (msg, vm, trace) => {
    return null
  }

  if (config.mode === 'production') setupSentry(app, router, config)

  app.mixin(VueHeadMixin)
  app
    .use(router)
    .use(store)
    .use(VueCookies)
    .use(createHead())
    .use(VueGtag, { bootstrap: false }, router)
    .use(i18n)
    .directive('swipe', SwipeDirective)

  app.config.globalProperties.emitter = emitter
  app.config.globalProperties.replaceBucketUrlWithCdnUrl =
    replaceBucketUrlWithCdnUrl
  app.config.globalProperties.fetchCurrencySymbol = fetchCurrencySymbol
  app.config.globalProperties.truncateText = truncateText

  app.directive('highlight', highlight)
  app.provide('emitter', emitter)
  app.mount('#app')

  if (config.googleAnalyticId) {
    ga('create', config.googleAnalyticId, 'auto')
    router.afterEach((to, from) => {
      const cat = fetchCat()
      if (to.name !== from.name) {
        ga('set', 'page', to.name)
        ga('send', 'pageview')
        if (cat) {
          ga(
            'send',
            'event',
            'pageview',
            to.name,
            cat.locationId || cat.location_id,
            1
          )
        }
      }
    })
  }
})()

// Vue.config.productionTip = false

if (
  'serviceWorker' in navigator &&
  !window.location.pathname?.startsWith('/courses')
) {
  navigator.serviceWorker
    .register('/membership-sw.js', { scope: '.' })
    .then((registration) => {
      console.log(registration)
    })
    .catch(function (error) {
      console.error('Failed to register Service Worker:', error)
    })
}
