import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "flex items-center justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0" }
const _hoisted_3 = { class: "flex justify-between items-center mt-2 p-2" }
const _hoisted_4 = { class: "px-2 font-semibold" }
const _hoisted_5 = { class: "flex flex-col py-2" }
const _hoisted_6 = { class: "px-2 flex flex-col md:pl-4 md:flex-row items-start mb-4" }
const _hoisted_7 = { class: "relative offer-poster-image w-full md:w-3/10 py-2" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = {
  class: "absolute top-0 right-1 mx-3 md:mx-0 my-3 px-1 rounded text-gray-900 text-xs",
  style: {"background-color":"#ffbc00"}
}
const _hoisted_10 = { class: "flex flex-col w-full md:w-9/12 md:px-2" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "px-2 my-4 text-base md:h-12"
}
const _hoisted_13 = { class: "flex flex-col md:flex-row justify-center md:w-full md:justify-end mt-2 mb-4 md:ml-2" }
const _hoisted_14 = { class: "inline-flex items-end pl-2 pr-2 text-center text-xs" }
const _hoisted_15 = { class: "mr-1" }
const _hoisted_16 = {
  key: 0,
  id: "offer-price-display-override"
}
const _hoisted_17 = {
  key: 1,
  id: "offer-amount"
}
const _hoisted_18 = ["aria-label"]
const _hoisted_19 = {
  key: 0,
  id: "payment-form",
  class: "px-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_UISpinner = _resolveComponent("UISpinner")!
  const _component_NewProductCard = _resolveComponent("NewProductCard")!
  const _component_PaymentModal = _resolveComponent("PaymentModal")!

  return (_ctx.openModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["fixed z-50 inset-0 overflow-y-auto", [_ctx.isPortalActive ? 'pt-14' : '']]),
        "aria-label": _ctx.t('upsellModal.accessibility.modalTitle'),
        role: "dialog",
        "aria-modal": "true"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[6] || (_cache[6] = _createElementVNode("div", {
            class: "fixed inset-0 bg-gray-900 opacity-75 transition-opacity",
            "aria-hidden": "true"
          }, null, -1)),
          _cache[7] || (_cache[7] = _createElementVNode("span", {
            class: "hidden sm:inline-block sm:align-middle sm:h-screen",
            "aria-hidden": "true"
          }, "​", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["ui-modal inline-block relative align-start rounded-lg text-left overflow-scroll bg-white shadow-xl transform transition-all h-9/10-screen my-8 lg:align-start md:align-start w-9/12 md:8/12 lg:w-6/12", 
          _ctx.offer.products && _ctx.offer.products.length > 3
            ? 'md:h-9/10-screen'
            : 'md:h-6/10-screen'
        ]),
            style: {"background-color":"#f5f5f5"}
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.offer.title), 1),
              _createElementVNode("div", {
                class: "px-2 cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _createVNode(_component_BaseIcon, {
                  name: "cross",
                  hwClass: "w-4 h-4",
                  "aria-label": _ctx.t('upsellModal.actions.close')
                }, null, 8, ["aria-label"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: 
                  _ctx.replaceBucketUrlWithCdnUrl(_ctx.offer.posterImage) ||
                  _ctx.defaultPosterImage
                ,
                    class: _normalizeClass(["object-contain w-full rounded", !_ctx.offer.posterImage ? 'h-28' : 'h-full']),
                    alt: _ctx.t('upsellModal.accessibility.offerImage')
                  }, null, 10, _hoisted_8),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('upsellModal.bundleLabel')), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex justify-between text-lg font-semibold text-gray-900 items-center lg:items-start md:px-2" }, null, -1)),
                  (_ctx.offerDescription)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        innerHTML: _ctx.offerDescription,
                        class: "px-2 text-base custom-list-styling editor-content"
                      }, null, 8, _hoisted_11))
                    : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.t('upsellModal.noDescription')), 1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('checkout'))),
                      class: "p-2 text-center text-blue-600 text-xs cursor-pointer mb-2 md:mb-0"
                    }, _toDisplayString(_ctx.t('upsellModal.actions.learnMore')), 1),
                    (_ctx.loader)
                      ? (_openBlock(), _createBlock(_component_UISpinner, {
                          key: 0,
                          color: "#4299e1",
                          class: "min-w-48 flex justify-center items-center p-2 mx-3",
                          size: "extra-small"
                        }))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["flex items-center rounded bg-blue-500 text-white font-semibold p-2 min-w-48 upsell__purchase-btn mx-3", [
                    _ctx.loader ? 'hidden' : 'block',
                    !_ctx.isAdmin && 'cursor-pointer',
                  ]]),
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.checkout && _ctx.checkout(...args)))
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["text-left inline-flex items-center", {
                      'opacity-75 cursor-not-allowed': _ctx.disableCheckoutBtn,
                    }])
                      }, [
                        (_ctx.disableCheckoutBtn)
                          ? (_openBlock(), _createBlock(_component_UISpinner, {
                              key: 0,
                              color: "#ffffff",
                              class: "pr-2",
                              size: "small"
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_BaseIcon, {
                          name: "cart",
                          hwClass: "w-5 h-5 text-white",
                          "aria-label": _ctx.t('upsellModal.cart.icon')
                        }, null, 8, ["aria-label"])
                      ], 2),
                      _createElementVNode("span", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t('upsellModal.actions.enroll.text')), 1),
                        (_ctx.offer.priceDisplayOverride)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.offer.priceDisplayOverride), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.fetchCurrencySymbol(_ctx.offer.currency)) + _toDisplayString(Number(_ctx.offer.amount).toFixed(2)), 1))
                      ])
                    ], 2)
                  ])
                ])
              ]),
              _createElementVNode("div", {
                id: "bundle-products",
                class: "px-2 grid md:grid-cols-3 row-gap-6 justify-center md:gap-5 mt-0 ml-3 lg:ml-0 justify-center",
                "aria-label": _ctx.t('upsellModal.accessibility.bundleProducts')
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offer.products, (product) => {
                  return (_openBlock(), _createBlock(_component_NewProductCard, {
                    key: product.id,
                    class: "bg-white",
                    "product-id": product.id,
                    "product-title": product.title,
                    "poster-image": product.posterImage,
                    "instructor-name": product.customizations.instructorName,
                    "instructor-headshot": product.customizations.instructorHeadshot,
                    upsellProduct: true,
                    label: 
                  _ctx.purchasedProductIds.indexOf(product.id) !== -1
                    ? 'in-library'
                    : 'locked'
                ,
                    showProgress: false,
                    onHandleClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('checkout')))
                  }, null, 8, ["product-id", "product-title", "poster-image", "instructor-name", "instructor-headshot", "label"]))
                }), 128))
              ], 8, _hoisted_18)
            ])
          ], 2)
        ]),
        (_ctx.showPaymentForm || _ctx.showThankYouMsg)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _createVNode(_component_PaymentModal, {
                showThankYouMsg: _ctx.showThankYouMsg,
                freeProduct: _ctx.freeProduct,
                contact: _ctx.contact,
                "show-modal": _ctx.showModal,
                onClose: _cache[4] || (_cache[4] = () => (_ctx.showModal = false)),
                location: _ctx.location,
                paymentMethods: _ctx.paymentMethods,
                locationId: _ctx.offer.locationId,
                offerId: _ctx.offer.id,
                isUpsell: true,
                showCoupon: _ctx.showCoupons,
                upsellType: _ctx.UpsellOfferType.INAPP,
                offer: _ctx.offer,
                checkoutSource: _ctx.CheckoutSourceType.INAPP,
                onOrderSuccessful: _ctx.orderConfirmation
              }, null, 8, ["showThankYouMsg", "freeProduct", "contact", "show-modal", "location", "paymentMethods", "locationId", "offerId", "showCoupon", "upsellType", "offer", "checkoutSource", "onOrderSuccessful"])
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}