import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = ["src", "alt"]

import { ref, computed, useAttrs } from 'vue'
import { twMerge } from 'tailwind-merge'
import { useI18n } from 'vue-i18n'

interface Props {
  url: string
  shape: 'square' | 'rounded' | 'circle'
  preview: boolean
  imageClass?: string
  imageStyle?: string
  previewClass?: string
  previewStyle?: string
  imgClass?: string
}

const imageId = 'image-component'
const modalId = 'image-modal'
const modalContentId = 'modal-content'
const closeButtonId = 'close-button'
const previewImageId = 'preview-image'


export default /*@__PURE__*/_defineComponent({
  __name: 'Image',
  props: {
    url: {},
    shape: {},
    preview: { type: Boolean },
    imageClass: {},
    imageStyle: {},
    previewClass: {},
    previewStyle: {},
    imgClass: {}
  },
  setup(__props: any) {

const props = __props

const showModal = ref(false)

const imageClass = computed(() => {
  switch (props.shape) {
    case 'rounded':
      return 'rounded-lg'
    case 'circle':
      return 'rounded-full'
    default:
      return ''
  }
})

const attrs = useAttrs()

const mergedImageClass = computed(() => {
  return twMerge(imageClass.value, props.imageClass, props.imgClass)
})

const mergedPreviewClass = computed(() => {
  return twMerge(props.previewClass)
})

const imageStyle = computed(() => props.imageStyle || '')
const previewStyle = computed(() => props.previewStyle || '')

const toggleModal = () => {
  if (props.preview) {
    showModal.value = !showModal.value
  }
}

const { t } = useI18n()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", _mergeProps({
      src: _ctx.url,
      class: [mergedImageClass.value, "cursor-pointer"],
      style: imageStyle.value,
      onClick: toggleModal,
      alt: _unref(attrs).alt || _unref(t)('common.image.defaultAlt')
    }, _unref(attrs)), null, 16, _hoisted_1),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (showModal.value && _ctx.preview)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                id: modalId,
                class: "fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center transition-opacity duration-300 z-[2000]",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (showModal.value = false))
              }, [
                _createElementVNode("button", {
                  id: closeButtonId,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = false)),
                  class: "absolute top-4 right-4 text-white text-4xl z-[2001]",
                  "aria-label": _unref(t)('common.image.closePreview')
                }, " × ", 8, _hoisted_2),
                _createElementVNode("div", {
                  id: modalContentId,
                  class: "relative rounded-lg max-w-4xl w-4/5 flex justify-center items-center",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("img", {
                    id: previewImageId,
                    src: _ctx.url,
                    class: _normalizeClass([mergedPreviewClass.value, "w-full h-auto max-h-[90vh]"]),
                    style: _normalizeStyle(previewStyle.value),
                    alt: _unref(attrs).alt || _unref(t)('common.image.defaultAlt')
                  }, null, 14, _hoisted_3)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]))
  ]))
}
}

})