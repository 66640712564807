<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { UIModal, UIModalHeader, UIModalFooter } from '@gohighlevel/ghl-ui'

import { AlertCircleIcon } from '@gohighlevel/ghl-icons/24/outline'

const { t } = useI18n()

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits([
  'update:confirmed',
  'update:show',
  'redirect-to-current',
])

const showModal = ref(false)
onMounted(() => (showModal.value = props.show))
watch(
  () => props.show,
  (showModalVal: boolean) => (showModal.value = showModalVal)
)

function closeModal() {
  if (isPaymentProcessing.value) {
    return false
  }
  emit('update:show', false)
  showModal.value = false
}

function redirectPage() {
  emit('redirect-to-current')
}
const isPaymentProcessing = ref(false)
</script>
<template>
  <UIModal
    :width="300"
    :autoFocus="false"
    :maskClosable="true"
    :show="showModal"
    className="fixed top-80 left-0 right-0 flex justify-center"
  >
    <template #header>
      <UIModalHeader
        :type="'error'"
        :title="t('payment.failed.title')"
        :description="t('payment.failed.description')"
        :icon="AlertCircleIcon"
        @close="closeModal"
        id="modal-header"
      />
    </template>
    <template #footer>
      <UIModalFooter
        :positiveText="t('common.button.cancel')"
        @positive-click="redirectPage"
        :loading="false"
        :disabled="false"
        id="modal-footer"
        :type="'error'"
        :disableNegativeBtn="false"
      >
      </UIModalFooter>
    </template>
  </UIModal>
</template>
<style scoped>
.n-button {
  height: 44px !important;
}
</style>
