<template>
  <div>
    <div
      class="relative upsell-card lg:m-4 transform transition duration-300 lg:hover:scale-105"
      :class="newMobileScreens ? 'h-full' : 'max-w-xs max-h-96'"
    >
      <div
        @click="openUpsell"
        class="w-full h-full content z-10 relative nova-product-card bg-white overflow-hidden cursor-pointer"
        :class="[
          newMobileScreens
            ? !notInCarousel
              ? 'shadow-none'
              : 'shadow rounded'
            : 'shadow rounded border border-gray-300 lg:hover:shadow-md',
        ]"
      >
        <div
          class="flex justify-center items-center product-image bg-center bg-contain bg-no-repeat w-full relative"
          :class="
            newMobileScreens && !notInCarousel
              ? 'shadow rounded-lg'
              : notInCarousel
              ? ''
              : 'border-b'
          "
        >
          <div
            v-if="!newMobileScreens"
            class="block absolute top-0 left-0 z-1 w-full h-full"
            :style="{ backdropFilter: 'blur(4px)' }"
          ></div>
          <img
            class="h-full rounded-lg lg:rounded-none w-full"
            :src="posterImage"
            :alt="t('product.upsell.posterImageAlt')"
          />
          <div
            v-if="newMobileScreens"
            class="absolute text-xs rounded text-white px-1 right-0 bottom-0 mr-2 mb-2 z-1"
            :class="
              newMobileScreens ? getMobileTheme.primary : 'bg-nova-blue-600'
            "
          >
            <span
              id="offer-price-display-override"
              v-if="offer.priceDisplayOverride"
            >
              {{ offer.priceDisplayOverride }}
            </span>
            <span id="offer-amount" v-else>
              {{ fetchCurrencySymbol(offer.currency)
              }}{{ Number(offer.amount).toFixed(2) }}
            </span>
          </div>
          <div
            class="locked-icon absolute w-5 h-5 lg:w-8 lg:h-8 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
            :style="{ color: '#FFBC00' }"
          >
            <BaseIcon name="lockfilled" hwClass="w-4 h-4" />
          </div>
        </div>
        <div
          class="absolute top-0 right-0 m-2 lg:py-1 lg:px-3 px-1 rounded text-gray-900 text-xs lg:text-sm"
          style="background-color: #ffbc00"
          v-if="offer?.products && offer.products?.length !== 1"
        >
          {{ t('product.upsell.bundle') }}
        </div>
        <div
          class="product-details lg:py-2 lg:my-1"
          :class="newMobileScreens ? '' : 'h-40'"
        >
          <div class="p-2 lg:px-2 lg:py-1">
            <div
              class="font-semibold product-title"
              :class="
                newMobileScreens
                  ? !notInCarousel && 'text-center'
                  : 'text-left text-lg'
              "
            >
              {{ truncateText(courseData.title) }}
            </div>
          </div>
          <div
            class="px-2 pb-2 pt-0"
            v-if="courseData.description && !newMobileScreens"
          >
            <div
              class="text-gray-800 text-sm product-title custom-list-styling"
              v-html="courseData.description"
            ></div>
          </div>
        </div>
        <div
          v-if="!newMobileScreens"
          class="w-full border-t pt-1 absolute bottom-0"
        >
          <div class="flex items-center justify-between py-1 px-2 w-full">
            <div class="flex items-center">
              <div id="instructor-headshot">
                <img
                  class="h-12 w-12 rounded-full"
                  :src="
                    replaceBucketUrlWithCdnUrl(
                      courseData.instructorHeadshot || userAvatarImage
                    )
                  "
                  :alt="t('product.upsell.instructor.imageAlt')"
                />
              </div>
              <div
                id="instructor-name"
                class="text-sm font-semibold text-blue-600 pl-2"
              >
                {{
                  truncateText(
                    courseData.instructorName || t('product.upsell.instructor.defaultName'),
                    instructorNameLimit
                  )
                }}
              </div>
            </div>
            <span
              class="offer-amount bg-nova-blue-900 py-1 px-2 rounded text-white text-sm"
            >
              <span
                id="offer-price-display-override"
                v-if="offer?.priceDisplayOverride"
              >
                {{ offer?.priceDisplayOverride }}
              </span>
              <span id="offer-amount" v-else>
                {{ fetchCurrencySymbol(offer.currency)
                }}{{ Number(offer.amount).toFixed(2) }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="
          offer?.products && offer.products?.length > 1 && !newMobileScreens
        "
        class="absolute h-full w-full shadow bg-white stack-1 rounded border-r border-b"
      ></div>
      <div
        v-if="
          offer?.products && offer.products?.length > 1 && !newMobileScreens
        "
        class="absolute h-full w-full shadow bg-white stack-2 rounded border-r border-b"
      ></div>
    </div>
    <UpsellModal
      v-if="showModal"
      :showUpsellModal="showModal"
      :offer="offer"
      :upsellId="upsellId"
      :purchasedProductIds="purchasedProductIds"
      @close="showModal = false"
      @checkout="handleUpsellCheckout"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  defaultPosterImage,
  userAvatarImage,
  INSTRUCTOR_NAME_MAX_LENGTH,
} from '../../helper/constants'
import { getMobileTheme } from '@/helper'
import UpsellModal from '@/components/common/UpsellModal.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { replaceBucketUrlWithCdnUrl } from '@/helper/filter'

export default defineComponent({
  components: { UpsellModal, BaseIcon },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  props: {
    upsellId: {
      type: String,
    },
    offer: {
      type: Object,
    },
    purchasedProductIds: {
      type: Array,
    },
    notInCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userAvatarImage,
      defaultPosterImage,
      isMobile: false,
      showModal: false,
      instructorNameLimit: INSTRUCTOR_NAME_MAX_LENGTH,
    }
  },
  computed: {
    courseData(): any {
      if (this.offer?.products?.length === 1) {
        const product = this.offer.products[0]
        return {
          title: product.title,
          posterImage: product.posterImage,
          description: this.truncateText(product.description, 75),
          instructorName:
            product.customizations?.instructorName || 'Instructor Name',
          instructorHeadshot: product.customizations?.instructorHeadshot,
        }
      } else {
        return {
          title: this.offer?.title,
          posterImage: this.offer?.posterImage,
          description:
            this.offer?.description &&
            this.truncateText(this.offer?.description, 75),
          instructorName: 'Multiple Instructors',
          instructorHeadshot: null,
        }
      }
    },
    posterImage(): string {
      return replaceBucketUrlWithCdnUrl(
        this.courseData.posterImage || defaultPosterImage
      )
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  methods: {
    openUpsell() {
      if (this.newMobileScreens) {
        this.$router.push({
          name: 'upsell-bundle',
          params: {
            offerId: this.offer.id,
            upsellId: this.upsellId,
          },
        })
        return
      }
      if (this.offer?.products?.length == 1) {
        this.handleUpsellCheckout()
      } else {
        this.showModal = true
        return
      }
    },
    handleUpsellCheckout() {
      this.emitter.emit('handleUpsellCheckout', this.upsellId)
    },
  },
})
</script>
<style scoped>
.product-title {
  word-break: break-word;
}

.product-image > img {
  aspect-ratio: 16/9;
  object-fit: contain;
}

.stack-1 {
  top: 4px;
  left: 4px;
  z-index: 3;
}

.stack-2 {
  top: 8px;
  left: 8px;
  z-index: 2;
}
</style>
