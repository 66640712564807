<script lang="ts" setup>
import {
  UIModal,
  UIModalHeader,
  UIButton,
  UIModalContent,
  UIDisplaySmMedium,
  UITextXsMedium,
} from '@gohighlevel/ghl-ui'
import CouponForm from '@/components/checkout/CouponForm.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  isCouponModalActive: {
    type: Boolean,
    default: false,
  },
  offer: {
    type: Object,
    default: () => ({}),
  },
  appliedCouponData: {
    type: Object,
    default: null,
  },
  localCouponData: {
    type: Object,
    default: null,
  },
  currency: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['toggleCouponModal', 'updateCouponData'])
</script>
<template>
  <UIModal :show="isCouponModalActive" :width="400">
    <template #header>
      <UIModalHeader
        type="primary"
        id="coupon-modal-header"
        :icon="null"
        :title="t('checkout.coupon.modal.title')"
        @close="() => emit('toggleCouponModal')"
      />
    </template>
    <UIModalContent>
      <UITextXsMedium class="amount-paid-text text-gray-700 font-family-inter">
        {{ t('checkout.label.total') }}
      </UITextXsMedium>
      <UIDisplaySmMedium class="price-text text-gray-900 font-family-inter">
        {{
          `${currency}${
            localCouponData ? localCouponData?.total : offer.amount
          }`
        }}
      </UIDisplaySmMedium>
      <CouponForm
        class="w-full mt-4 bg-white rounded-lg"
        :offer="offer"
        :borders="false"
        :showLabel="false"
        :appliedData="appliedCouponData"
        @updateCouponData="(data) => emit('updateCouponData', data)"
      />
    </UIModalContent>
    <UIButton
      @click="() => emit('toggleCouponModal')"
      id="coupon-checkout"
      type="primary"
      class="w-full"
    >
      {{ t('common.button.proceed') }}
    </UIButton>
  </UIModal>
</template>
