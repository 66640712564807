<template>
  <div>
    <MobileSearch
      v-if="newMobileScreens && activeLibrary"
      :searchFullText="searchFullText"
      :searchContent="searchContent"
      :resetData="resetData"
      :fetchProductTitle="fetchProductTitle"
      :changeRoute="changeRoute"
      :searchCount="searchCount"
      :nextSearchData="nextSearchData"
    />
    <div
      v-else-if="openModal"
      class="fixed z-[100] inset-0 overflow-y-auto pt-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="flex items-center justify-center items-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >

        <div
          class="ui-modal inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all h-40 my-8 lg:align-middle md:align-middle w-11/12 lg:w-2/5 md:w-2/3"
        >
          <div class="flex flex-col bg-white py-2 sm:p-6 sm:pb-4">
            <div
              class="h-1.5 p-2 md:px-0 lg:px-0 cursor-pointer pb-4"
              @click="closeModal"
            >
              <BaseIcon
                name="cross"
                hwClass="w-4 h-4 float-right"
                :aria-label="t('universalSearch.closeButton')"
              />
            </div>
            <div class="sm:flex sm:items-start">
              <div class="w-full text-center sm:text-left h-16">
                <div class="flex border-b border-gray-500 px-2">
                  <div class="py-1 mt-2">
                    <BaseIcon
                      name="search"
                      hwClass="w-5 h-5"
                      :aria-label="t('universalSearch.searchIcon')"
                    />
                  </div>
                  <input
                    id="search"
                    v-model.trim="searchText"
                    @input="createDebounce()"
                    :aria-label="t('universalSearch.searchInput.label')"
                    name="search"
                    type="text"
                    maxlength="64"
                    class="appearance-none rounded-none relative block w-full focus:outline-none placeholder-gray-600 text-gray-900 sm:text-base sm:leading-5 placeholder:text-base placeholder:leading-5 pl-4"
                    :placeholder="t('universalSearch.searchInput.placeholder')"
                    :style="{ 'text-align-last': 'start' }"
                  />
                </div>
                <div class="w-full">
                  <div v-if="searchText" id="searchList">
                    <div
                      v-if="searchData.length || loading"
                      id="optionList"
                      class="block py-2"
                    >
                      <div
                        class="searchList flex flex-col my-2 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-300 leading-5"
                        v-for="option in searchData"
                        :key="option.typeId"
                        @click.prevent="changeRoute(option)"
                      >
                        <div class="p-2 text-left flex justify-between">
                          <span class="text-gray-900 text-sm">{{
                            option.title.length > 50
                              ? `${option.title.substring(0, 40)}...`
                              : option.title
                          }}</span>
                          <img
                            class="text-gray-700 h-5"
                            :src="searchIcons[option.type]"
                            :alt="t('universalSearch.productIcon')"
                          />
                        </div>
                        <div
                          v-if="option.description"
                          v-html="option.description"
                          class="px-2 text-left text-xs h-auto"
                        ></div>
                        <div
                          v-if="option.type !== 'product'"
                          class="product-title px-2 text-left font-semibold text-xs h-5"
                          :class="option.description ? 'p-2' : 'pb-2'"
                        >
                          {{ productTitleMapping[option.productId] }}
                        </div>
                      </div>
                      <div
                        class="px-4 py-2 flex justify-center items-center text-gray-700"
                        v-if="!allDataLoaded || loading"
                        ref="loadMore"
                      >
                        <span class="inline-flex h-6 w-6">
                          <UISpinner size="small" />
                        </span>
                      </div>
                    </div>
                    <div
                      v-else-if="!isSearchDataAvailable"
                      class="block w-full mr-4 px-2 py-2 text-sm leading-5 text-gray-700"
                    >
                      {{ t('universalSearch.noResults.text') }}
                      <span class="font-semibold">"{{ searchText }}"</span>.
                      {{ t('universalSearch.noResults.suggestion') }}
                    </div>
                  </div>
                  <div
                    v-else-if="recentSearches.length"
                    class="p-2 text-gray-700"
                  >
                    <div class="text-left">
                      {{ t('universalSearch.recentSearches.title') }}
                    </div>
                    <div
                      class="searchList flex flex-col my-2 pr-6 rounded py-2 text-gray-700 cursor-pointer bg-gray-200 hover:bg-gray-300 leading-5"
                      v-for="(option, index) in recentSearches"
                      :key="index"
                      @click.prevent="
                        () => {
                          searchText = option
                          searchContent()
                        }
                      "
                    >
                      <div class="py-1 px-2 text-left">
                        {{ option }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="p-2 text-gray-700">
                    {{ t('universalSearch.recentSearches.empty') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { debounce } from 'lodash-es'
import { productIcon, folderIcon, fileIcon } from '../../helper/constants'
import { UniversalSearchService } from '../../services'
import { getHighlightedDescription } from '@/helper'
import MobileSearch from './MobileSearch.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import UISpinner from '@/components/common/UISpinner.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    MobileSearch,
    BaseIcon,
    UISpinner,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'Desktop',
    },
  },
  data() {
    return {
      searchText: '',
      open: true,
      limit: 30,
      page: 1,
      totalSearchCount: 0,
      searchData: [],
      loading: false,
      openModal: false,
      isSearchDataAvailable: true,
      recentSearches: [],
      searchIds: [],
      productTitleMapping: {},
      uniqueProductIds: [],
      searchIcons: {
        product: productIcon,
        category: folderIcon,
        post: fileIcon,
      },
      observer: null as any,
      allDataLoaded: false,
      error: '',
    }
  },
  computed: {
    isFullScreen() {
      return this.$route.params.isFullScreen
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    activeLibrary(): string {
      return this.$store.getters.activeLibrary
    },
  },
  async mounted() {
    this.recentSearches =
      JSON.parse(window.localStorage.getItem('searchItems')) || []
    this.observer = new IntersectionObserver(this.loadMore)
    if (!this.allDataLoaded) {
      await this.$nextTick()
      if (this.$refs.loadMore) {
        this.observer.observe(this.$refs.loadMore)
      }
    }
  },
  watch: {
    showModal(val: boolean) {
      this.openModal = val
    },
  },
  methods: {
    closeModal() {
      this.searchText = ''
      this.resetData()
      this.$emit('close')
    },
    createDebounce: debounce(function () {
      this.searchContent()
    }, 500),
    async searchFullText() {
      this.loading = true
      const payload = {
        searchKey: this.searchText,
        pageLimit: this.limit,
        pageNumber: this.page,
      }
      const newData = await UniversalSearchService.searchItems(payload)
      const productIds = []
      this.allDataLoaded = !newData.length
      this.page = this.page + 1
      if (newData.length) {
        for (const data of newData) {
          if (!this.searchIds.includes(data.typeId)) {
            if (!this.uniqueProductIds.includes(data.productId)) {
              this.uniqueProductIds.push(data.productId)
              productIds.push(data.productId)
            }
            if (data.description) {
              data.description = getHighlightedDescription(
                data.description,
                this.searchText.split(' '),
                0
              )
            }
            this.searchData.push(data)
            this.searchIds.push(data.typeId)
          }
        }
        if (productIds.length) {
          this.fetchProductTitle(productIds)
        }
        this.isSearchDataAvailable = true
        // this.nextSearchData()
        setTimeout(() => {
          if (this.$refs.loadMore) {
            this.observer.observe(this.$refs.loadMore)
          }
        }, 0)
      } else {
        this.isSearchDataAvailable = false
      }
      this.loading = false
    },
    searchContent() {
      this.resetData()
      if (!this.searchText || this.searchText.length < 4) {
        this.isSearchDataAvailable = true
        return
      }
      // this.searchCount()
      this.searchFullText()
    },
    resetData() {
      this.page = 1
      this.searchData = []
      this.searchIds = []
      this.productTitleMapping = {}
      this.uniqueProductIds = []
    },
    async fetchProductTitle(productIds) {
      const products = await UniversalSearchService.getProductTitles({
        productIds: productIds,
      })
      this.productTitleMapping = { ...this.productTitleMapping, ...products }
    },
    changeRoute(option) {
      this.recentSearches =
        JSON.parse(window.localStorage.getItem('searchItems')) || []
      if (!this.recentSearches.includes(this.searchText.toLocaleLowerCase())) {
        if (this.recentSearches.length === 3) this.recentSearches.pop()
        this.recentSearches.splice(0, 0, this.searchText.toLocaleLowerCase())
      }
      window.localStorage.setItem(
        'searchItems',
        JSON.stringify(this.recentSearches)
      )
      this.searchText = ''
      this.searchData = []
      this.$router.push({
        name:
          option.type === 'product'
            ? 'product-overview'
            : option.type === 'category'
            ? 'category-overview'
            : 'post-overview',
        params: {
          id: option.productId,
          category_id: option.categoryId,
          post_id: option.typeId,
        },
        query: this.$route.query,
      })
      this.closeModal()
    },
    async searchCount() {
      const payload = {
        searchKey: this.searchText,
      }
      const { totalCount } = await UniversalSearchService.searchCount(payload)
      this.totalSearchCount = totalCount
    },
    nextSearchData() {
      const optionList = document.getElementById('optionList')
      if (optionList) {
        optionList.onscroll = () => {
          const offset = (this.page + 1) * this.limit - this.limit
          if (this.totalSearchCount - offset < 0) {
            return
          }
          const lastDiv = document.querySelector('#optionList > div:last-child')
          const optionListOffset =
            optionList.scrollTop + optionList.clientHeight
          const lastDivOffset = lastDiv.scrollTop + lastDiv.clientHeight
          if (optionListOffset > lastDivOffset - 10) {
            this.page++
            this.searchFullText()
          }
        }
      }
    },
    async loadMore([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        await this.searchFullText()
        ul.scrollTop = scrollTop
      }
    },
  },
})
</script>
<style scoped>
.ui-modal {
  height: 35rem;
}

.searchList {
  width: 97%;
  overflow: hidden;
}

#optionList {
  height: 30rem;
  overflow-y: scroll;
}

.product-title {
  color: rgba(166, 166, 166, 1);
}

@media (max-width: 450px) {
  .ui-modal {
    height: 30rem;
  }

  #optionList {
    height: 25rem;
  }

  .searchList {
    margin-left: 0.25rem;
    margin-right: 1rem;
  }
}
</style>
