import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, withCtx as _withCtx, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = { class: "content-container w-dvw relative pb-8" }
const _hoisted_3 = { class: "courses-tab w-11/12 mx-auto flex items-center justify-evenly gap-4" }

import Navigation from '@/components/common/Navigation.vue'
// import AllCoursesV2 from '@/components/mobile/AllCoursesV2.vue'
// import MyCoursesV2 from '@/components/mobile/MyCoursesV2.vue'
import TabComponentV2 from '@/components/mobile/TabComponentV2.vue'
import { COURSES_V2_TABS } from '@/helper/constants'
import UpsellOffer from '@/models/UpsellOffer'
import { UserPurchaseService } from '@/services'
import { UIEmpty } from '@gohighlevel/ghl-ui'
import { computed, defineAsyncComponent, h, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import CoursesShimmerV2 from '../../components/shimmer/mobile/CoursesShimmerV2.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileLibraryV2',
  setup(__props) {

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const selectedTab = ref('all_courses')
const fetching = ref(false)
const products = ref([])
const upsellOffers = ref<UpsellOffer[]>([])
const filteredUpsellOffers = ref<UpsellOffer[]>([])
const allCourses = ref([])
const purchasedProductIds = ref([])
const isUpsellOfferAvailable = ref(false)

const AllCoursesV2 = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: "AllCoursesV2" */ '@/components/mobile/AllCoursesV2.vue'
    ),
  loadingComponent: CoursesShimmerV2,
  errorComponent: () =>
    h('div', { class: '!mt-8 !mb-8' }, [
      h(UIEmpty, {
        id: 'empty-product-message',
        title: t('views.mobile.library.error.title'),
        description: t('views.mobile.library.error.description'),
      }),
    ]),
  delay: 0,
  timeout: 3000,
})

const MyCoursesV2 = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: "MyCoursesV2" */ '@/components/mobile/MyCoursesV2.vue'
    ),
  loadingComponent: CoursesShimmerV2,
  errorComponent: () =>
    h('div', { class: '!mt-8 !mb-8' }, [
      h(UIEmpty, {
        id: 'empty-product-message',
        title: t('views.mobile.library.error.title'),
        description: t('views.mobile.library.error.description'),
      }),
    ]),
  delay: 0,
  timeout: 3000,
})

const isLibraryPage = computed(() => {
  const libraryPathNames = ['library-v2', 'library-v2-v2']
  return libraryPathNames.includes(String(route.name))
})
const selectedTabComponent = computed(() => {
  return selectedTab.value === 'all_courses'
    ? h(AllCoursesV2, {
        allCourses: allCourses.value,
        upsellOffers: filteredUpsellOffers.value,
      })
    : h(MyCoursesV2, { myCourses: products.value })
})

onMounted(async () => {
  fetching.value = true
  await fetchUserProducts()
  await fetchUpsellBundle()
  fetching.value = false
})

async function fetchUserProducts() {
  try {
    products.value = await UserPurchaseService.getAllProducts({
      getPostCount: true,
    })
    purchasedProductIds.value = products.value.map((p) => p.id)
  } catch (error) {
    console.error('Error while fetching products --> ', error)
  }
}
async function fetchUpsellBundle() {
  try {
    const upsellOffersArr = await UserPurchaseService.getBundleOffers()
    const set = new Set()
    upsellOffers.value = upsellOffersArr.filter((offer) => {
      if (!set.has(offer.upsellOfferId)) {
        set.add(offer.upsellOfferId)
        return true
      }
      return false
    })

    if (upsellOffers.value.length > 0) {
      isUpsellOfferAvailable.value = true
      filteredUpsellOffers.value = upsellOffers.value.filter((offer) => {
        return offer?.upsellType && offer?.offerUpSell
      })
    }
    allCourses.value = [...upsellOffers.value, ...products.value]
  } catch (error) {
    console.error('Error while fetching upsell bundles --> ', error)
  }
}
async function backCallback() {
  router.back()
}

function handleTabChange(tab: string) {
  selectedTab.value = tab
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Navigation, {
      backCallback: !isLibraryPage.value ? backCallback : null
    }, null, 8, ["backCallback"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(TabComponentV2, {
          selectedTab: selectedTab.value,
          tabOptions: _unref(COURSES_V2_TABS),
          onTabChange: handleTabChange
        }, null, 8, ["selectedTab", "tabOptions"])
      ]),
      (fetching.value)
        ? (_openBlock(), _createBlock(CoursesShimmerV2, { key: 0 }))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(selectedTabComponent.value), {
              fetching: fetching.value,
              class: "h-full"
            }, null, 8, ["fetching"]))
          ]),
          _: 1
        })
      ], 1024))
    ])
  ]))
}
}

})