import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "offer-stripe-payment",
  class: "px-2"
}
const _hoisted_2 = { class: "flex mb-4" }
const _hoisted_3 = { class: "flex-grow" }
const _hoisted_4 = {
  for: "stripe-card-number",
  class: "text-gray-700 text-sm text-left block"
}
const _hoisted_5 = { class: "flex mb-4" }
const _hoisted_6 = { class: "flex-auto mr-4" }
const _hoisted_7 = {
  for: "stripe-card-expiry",
  class: "text-gray-700 text-sm text-left block"
}
const _hoisted_8 = { class: "flex-auto" }
const _hoisted_9 = {
  for: "stripe-card-cvc",
  class: "text-gray-700 text-sm text-left block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(this.t('payment.stripe.card_number')), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", {
            id: "stripe-card-number",
            class: "stripe-input"
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(this.t('payment.stripe.expiry_date')), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", {
            id: "stripe-card-expiry",
            class: "stripe-input"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(this.t('payment.stripe.cvc')), 1),
          _cache[2] || (_cache[2] = _createElementVNode("div", {
            id: "stripe-card-cvc",
            class: "stripe-input"
          }, null, -1))
        ])
      ])
    ])
  ]))
}