import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full border-b border-[#E4E4E4] px-2 gap-4 flex items-center search-navigation" }
const _hoisted_2 = { class: "flex-grow flex items-center justify-start mx-auto my-4 border rounded-full border-[#EAECF0]" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "w-11/12 mx-auto my-4" }
const _hoisted_5 = {
  key: 0,
  id: "searchList"
}
const _hoisted_6 = {
  key: 0,
  class: "py-2 flex justify-center items-center text-gray-700",
  ref: "loadMore"
}
const _hoisted_7 = {
  key: 1,
  id: "optionList",
  class: "block py-2"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "p-2 text-left flex justify-between" }
const _hoisted_10 = { class: "text-gray-900 text-sm" }
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 2,
  class: "block w-full mr-4 px-2 py-2 text-sm leading-5 text-gray-700"
}
const _hoisted_14 = { class: "font-semibold" }
const _hoisted_15 = {
  key: 1,
  class: "p-2 text-gray-700"
}
const _hoisted_16 = { class: "text-left text-[#101828]" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "py-1 px-2 text-left" }
const _hoisted_19 = {
  key: 2,
  class: "p-2 text-gray-700"
}

import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import { getHighlightedDescription } from '@/helper'
import { SearchSmIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UIDrawer, UIDrawerContent } from '@gohighlevel/ghl-ui'
import { debounce } from 'lodash'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import GlobalSearchShimmerV2 from '../../components/shimmer/mobile/GlobalSearchShimmerV2.vue'
import {
  fileIcon,
  folderIcon,
  LIBRARY_MENU_ITEMS,
  productIcon,
} from '../../helper/constants'
import { UniversalSearchService } from '../../services'


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileSearchV2',
  setup(__props) {

const store = useStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const openSearchDrawer = computed(() => store.getters.openSearchDrawer)
const searchText = ref<string>('')

const limit = ref(30)
const page = ref(1)
const totalSearchCount = ref(0)
const searchData = ref([])
const loading = ref(false)
const isSearchDataAvailable = ref(true)
const recentSearches = ref([])
const searchIds = ref([])
const productTitleMapping = ref({})
const uniqueProductIds = ref([])
const allDataLoaded = ref(false)
const error = ref('')

const searchIcons = {
  product: productIcon,
  category: folderIcon,
  post: fileIcon,
}
const observer = ref<IntersectionObserver | null>(null)

const createDebounce = debounce(() => {
  searchContent()
}, 500)

onMounted(async () => {
  recentSearches.value = JSON.parse(
    window.localStorage.getItem('searchItems') || '[]'
  )
  observer.value = new IntersectionObserver(loadMore)
  if (!allDataLoaded.value) {
    await nextTick()
    const loadMoreRef = document.querySelector('#loadMore')
    if (loadMoreRef) {
      observer.value.observe(loadMoreRef)
    }
  }
})

async function searchFullText() {
  loading.value = true
  const payload = {
    searchKey: searchText.value,
    pageLimit: limit.value,
    pageNumber: page.value,
  }
  const newData = await UniversalSearchService.searchItems(payload)
  const productIds = []

  allDataLoaded.value = !newData.length
  page.value = page.value + 1
  if (newData.length) {
    for (const data of newData) {
      if (!searchIds.value.includes(data.typeId)) {
        if (!uniqueProductIds.value.includes(data.productId)) {
          uniqueProductIds.value.push(data.productId)
          productIds.push(data.productId)
        }
        if (data.description) {
          data.description = getHighlightedDescription(
            data.description,
            searchText.value.split(' '),
            0
          )
        }
        searchData.value.push(data)
        searchIds.value.push(data.typeId)
      }
    }
    if (productIds.length) {
      await fetchProductTitle(productIds)
    }
    isSearchDataAvailable.value = true
    const loadMoreRef = document.querySelector('#loadMore')
    if (loadMoreRef) {
      observer.value.observe(loadMoreRef)
    }
  } else {
    isSearchDataAvailable.value = false
  }
  loading.value = false
}

function searchContent() {
  resetData()
  if (!searchText.value) {
    isSearchDataAvailable.value = true
    return
  }
  searchFullText()
}

function resetData() {
  page.value = 1
  searchData.value = []
  searchIds.value = []
  productTitleMapping.value = {}
  uniqueProductIds.value = []
}

async function fetchProductTitle(productIds: string[]) {
  const products = await UniversalSearchService.getProductTitles({
    productIds: productIds,
  })
  productTitleMapping.value = { ...productTitleMapping.value, ...products }
}

function handleOpenSearchDrawer() {
  store.commit('SET_SEARCH_DRAWER_OPEN', !openSearchDrawer.value)
}

function changeRoute(option: any) {
  recentSearches.value = JSON.parse(
    window.localStorage.getItem('searchItems') || '[]'
  )
  if (!recentSearches.value.includes(searchText.value.toLowerCase())) {
    if (recentSearches.value.length === 3) recentSearches.value.pop()
    recentSearches.value.splice(0, 0, searchText.value.toLowerCase())
  }
  window.localStorage.setItem(
    'searchItems',
    JSON.stringify(recentSearches.value)
  )
  searchText.value = ''
  searchData.value = []
  router.push({
    name:
      option.type === 'product' || option.type === 'category'
        ? 'category-list'
        : 'post-overview',
    params: {
      id: option.productId,
      category_id: option.categoryId,
      post_id: option.typeId,
    },
    query: {
      ...route.query,
      category_id: option.categoryId,
      selected_tab: 'syllabus',
      from_search: 'true',
    },
  })
  handleOpenSearchDrawer()
  store.commit('UPDATE_ACTIVE_LIBRARY', LIBRARY_MENU_ITEMS.allCourses)
}

async function searchCount() {
  const payload = {
    searchKey: searchText.value,
  }
  const { totalCount } = await UniversalSearchService.searchCount(payload)
  totalSearchCount.value = totalCount
}

async function loadMore([
  { isIntersecting, target },
]: IntersectionObserverEntry[]) {
  if (isIntersecting) {
    const ul = target.offsetParent
    const scrollTop = target.offsetParent.scrollTop
    await searchFullText()
    if (ul) {
      ul.scrollTop = scrollTop
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UIDrawer), {
    show: openSearchDrawer.value,
    placement: 'right',
    id: 'drawer',
    displayDirective: 'if',
    "on-mask-click": handleOpenSearchDrawer,
    style: { width: '100dvw' }
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(UIDrawerContent), {
        id: "drawer-component",
        closable: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(BaseIcon, {
              name: "chevronleft",
              hwClass: "w-6 h-6",
              onClick: handleOpenSearchDrawer,
              class: "flex-shrink-0"
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(SearchSmIcon), { class: "w-4 h-4 text-gray-500 mx-3 my-3" }),
              _withDirectives(_createElementVNode("input", {
                class: "outline-none border-none w-11/12 placeholder:text-sm text-sm placeholder:text-start",
                placeholder: _unref(t)('search.placeholder'),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(createDebounce)())),
                "aria-label": "Search",
                type: "text",
                maxlength: "64",
                id: "search"
              }, null, 40, _hoisted_3), [
                [
                  _vModelText,
                  searchText.value,
                  void 0,
                  { trim: true }
                ]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (searchText.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (loading.value && !isSearchDataAvailable.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(GlobalSearchShimmerV2)
                      ], 512))
                    : (!loading.value && searchData.value.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchData.value, (option) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "searchList flex flex-col my-2 p-2 text-gray-800 cursor-pointer border-gray-50 rounded-lg bg-gray-50 hover:bg-gray-100 leading-5 gap-4",
                              key: option.typeId,
                              onClick: _withModifiers(($event: any) => (changeRoute(option)), ["prevent"])
                            }, [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("span", _hoisted_10, _toDisplayString(option.title.length > 50
                    ? `${option.title.substring(0, 40)}...`
                    : option.title), 1),
                                _createElementVNode("img", {
                                  class: "text-gray-700 h-5",
                                  src: searchIcons[option.type],
                                  alt: _unref(t)('search.productIcon.alt')
                                }, null, 8, _hoisted_11)
                              ]),
                              (option.description)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    innerHTML: option.description,
                                    class: "px-2 text-left text-xs h-auto"
                                  }, null, 8, _hoisted_12))
                                : _createCommentVNode("", true),
                              (option.type !== 'product')
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(["product-title px-2 text-left font-semibold text-xs", option.description ? 'p-2' : 'pb-2'])
                                  }, _toDisplayString(productTitleMapping.value[option.productId]), 3))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_8))
                          }), 128))
                        ]))
                      : (!searchText.value || !loading.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createTextVNode(_toDisplayString(_unref(t)('search.noResults.text')) + " ", 1),
                            _createElementVNode("span", _hoisted_14, "\"" + _toDisplayString(searchText.value) + "\"", 1),
                            _createTextVNode(". " + _toDisplayString(_unref(t)('search.noResults.tryAgain')), 1)
                          ]))
                        : _createCommentVNode("", true)
                ]))
              : (recentSearches.value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(t)('search.recentSearches')), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recentSearches.value, (option, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "searchList flex flex-col my-2 pr-6 p-2 text-[#909090] cursor-pointer border-gray-200 border-b leading-5",
                        key: index,
                        onClick: _withModifiers(
              () => {
                searchText.value = option
                searchContent()
              }
            , ["prevent"])
                      }, [
                        _createElementVNode("div", _hoisted_18, _toDisplayString(option), 1)
                      ], 8, _hoisted_17))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_unref(t)('search.noRecentSearches')), 1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})