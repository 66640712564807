import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { TopNavBar } from '@gohighlevel/clientportal-core'
import { BookOpen01Icon, SearchSmIcon } from '@gohighlevel/ghl-icons/24/outline'
import { UIButton } from '@gohighlevel/ghl-ui'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import BaseIcon from '../svgicons/BaseIcon.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileTopBarNavigationV2',
  props: {
  backCallback: Function,
},
  setup(__props) {



const store = useStore()
const route = useRoute()

const isPostDetailsPath = computed(() => {
  const postDetailsPath = ['post-overview', 'post-overview-v2']
  return postDetailsPath.includes(route.name as string)
})
const isAssignmentOrQuizPath = computed(() => {
  const assignmentDetailsPath = [
    'mobile-assignment',
    'mobile-assignment-v2',
    'mobile-assignment-result',
    'mobile-assignment-result-v2',
    'mobile-assignment-overview',
    'mobile-assignment-overview-v2',
  ]
  const quizDetailsPath = [
    'mobile-quiz-result',
    'mobile-quiz-result-v2',
    'mobile-quiz-questions-v2',
    'mobile-quiz-questions',
    'mobile-quiz-overview',
    'mobile-quiz-overview-v2',
  ]
  return (
    assignmentDetailsPath.includes(route.name as string) ||
    quizDetailsPath.includes(route.name as string)
  )
})
const isPortalActive = computed(() => {
  return isPortalActive
})

const handleOpenCurriculumComments = () => {
  store.commit(
    'SET_CURRICULUM_COMMENTS_OPEN',
    !store.getters.openCurriculumComments
  )
}
const handleSearchDrawer = () => {
  store.commit('SET_SEARCH_DRAWER_OPEN', !store.getters.openSearchDrawer)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TopNavBar), { App: 'Courses' }, {
    "filter-left": _withCtx(() => [
      (__props.backCallback)
        ? (_openBlock(), _createBlock(_unref(UIButton), {
            key: 0,
            onClick: __props.backCallback,
            name: "search",
            class: "global-search mr-[8px]",
            id: "btn-notification",
            type: "default",
            quaternary: true,
            circle: true
          }, {
            default: _withCtx(() => [
              _createVNode(BaseIcon, {
                name: "chevronleft",
                hwClass: "w-5 h-5 text-clientportal-font-secondary"
              })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    "filter-right": _withCtx(() => [
      _createVNode(_unref(UIButton), {
        name: "search",
        class: "global-search",
        onClick: handleSearchDrawer,
        id: "btn-notification",
        type: "default",
        quaternary: true,
        circle: true
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(SearchSmIcon), { class: "w-5 h-5 text-clientportal-font-secondary" })
        ]),
        _: 1
      }),
      (isPostDetailsPath.value || isAssignmentOrQuizPath.value)
        ? (_openBlock(), _createBlock(_unref(UIButton), {
            key: 0,
            name: "search",
            onClick: handleOpenCurriculumComments,
            id: "btn-notification",
            type: "default",
            quaternary: true,
            circle: true
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BookOpen01Icon), { class: "w-5 h-5 text-clientportal-font-secondary" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})