import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pwa-modal z-40 fixed top-0 left-0 w-full h-full"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-lg font-bold mt-3 mb-2" }
const _hoisted_4 = { class: "text-center px-2 mb-3 text-gray-600" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "text-center px-2 mb-3 text-gray-600" }
const _hoisted_8 = { class: "w-full text-gray-700 text-center flex justify-center items-center pt-4 border-t" }
const _hoisted_9 = { class: "mx-1" }
const _hoisted_10 = { class: "text-center px-2 mb-3 text-gray-600" }
const _hoisted_11 = { class: "w-full text-gray-700 text-center flex justify-center items-center pt-4 border-t" }
const _hoisted_12 = { class: "mx-1" }
const _hoisted_13 = { class: "text-center px-2 mb-3 text-gray-600" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 4,
  class: "w-5 h-5 absolute transform rotate-45 left-1/5 bg-white",
  style: {"bottom":"-0.625rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_ctx.open || _ctx.pwaTriggerInstallAlert)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full h-full bg-gray-900 opacity-75 transition-opacity" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["modal-content max-w-sm mb-5 bg-white absolute transform p-4 rounded-lg w-11/12 flex flex-col items-center", _ctx.getModalPosition(_ctx.browserName === 'safari' ? 'bottom' : 'center')])
        }, [
          _createElementVNode("div", {
            class: "flex w-full justify-end text-gray-600",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, [
            _createVNode(_component_BaseIcon, {
              name: "cross",
              hwClass: "w-4 h-4"
            })
          ]),
          _createElementVNode("img", {
            class: "w-12",
            src: _ctx.pwaAlertData.logo || _ctx.logo
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('mobileAlertModal.installTitle', { name: _ctx.pwaAlertData.name || _ctx.pageTitle })), 1),
          (
          _ctx.deviceName === 'iphone' &&
          (_ctx.browserName === 'chrome' || _ctx.browserName === '')
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('mobileAlertModal.openInSafari')), 1),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyLinkToClipboard && _ctx.copyLinkToClipboard(...args))),
                  class: "w-full rounded text-center p-2 bg-gray-300 font-bold"
                }, [
                  (_ctx.isCopied)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t('mobileAlertModal.copiedButton')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.t('mobileAlertModal.copyButton')), 1))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (
          (_ctx.deviceName === 'iphone' && _ctx.browserName === 'safari') ||
          (_ctx.isTablet && _ctx.deviceName !== 'android' && _ctx.browserName === 'safari')
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('mobileAlertModal.addToHomeScreen.description')), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createTextVNode(_toDisplayString(_ctx.t('mobileAlertModal.addToHomeScreen.instruction', { icon: '' })) + " ", 1),
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_BaseIcon, { name: "iosshare" })
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.deviceName === 'android' && _ctx.browserName === 'chrome')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('mobileAlertModal.androidInstall.description')), 1),
                _createElementVNode("div", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.t('mobileAlertModal.androidInstall.instruction', { icon: '' })) + " ", 1),
                  _createElementVNode("span", _hoisted_12, [
                    _createVNode(_component_BaseIcon, {
                      name: "dotsvertical",
                      hwClass: "w-5 h-5"
                    })
                  ])
                ])
              ], 64))
            : _createCommentVNode("", true),
          (
          _ctx.deviceName === 'android' &&
          (_ctx.browserName === 'safari' || _ctx.browserName === '')
        )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t('mobileAlertModal.openInChrome')), 1),
                _createElementVNode("div", {
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyLinkToClipboard && _ctx.copyLinkToClipboard(...args))),
                  class: "w-full rounded text-center p-2 bg-gray-300 font-bold"
                }, [
                  (_ctx.isCopied)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.t('mobileAlertModal.copiedButton')), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.t('mobileAlertModal.copyButton')), 1))
                ])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.browserName === 'safari' && _ctx.popupPos === 'bottom')
            ? (_openBlock(), _createElementBlock("div", _hoisted_16))
            : _createCommentVNode("", true)
        ], 2)
      ]))
    : _createCommentVNode("", true)
}