import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_3 = {
  key: 1,
  class: "flex p-2 text-red-700"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "pl-2" }

import PaymentWarningIcon from '@/assets/payment-warning.svg'
import { UI_PAYMENT_ELEMENT_EVENT } from '@/helper/constants'
import { extractAPIErrorMsg } from '@/helper/index'
import {
  base64ToUtf8,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@/helper/storage.helper'
import { UIPaymentElement } from '@gohighlevel/ghl-payment-element'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import config from '../../config'
import UISpinner from '../common/UISpinner.vue'
import Recaptcha from './captcha/Recaptcha.vue'
import { UIButton } from '@gohighlevel/ghl-ui'


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentV2',
  props: {
  size: String,
  locationId: String,
  contactId: String,
  contact: Object,
  sourceId: String, // offer id
  priceIds: String,
  purchaseV2: Function,
  isLivePayment: Boolean,
  offer: Object,
  closeModal: Function,
  togglePaymentFooter: Function,
  couponData: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props, { expose: __expose }) {

const route = useRoute()
const props = __props

const { t } = useI18n()

const isProcessingOrder = ref(false)
const isProcessingPayment = ref(false)
const isPaymentElementReady = ref(false)
const errorMsg = ref()
const paymentElementOptions = ref()
const paymentElementRef = ref()
const isLivePayment = ref(props.isLivePayment)
const showRecaptcha = ref(false)
const reCaptchaToken = ref(undefined)
const reCaptchaError = ref('')
const orderData = ref<any>(null)
const defaultProvider = ref('')
const showPaypalInPE = ref(true)
const disablePaypalButton = ref(false)
const showCustomProviderPaymentButton = ref(false)
const customProviderInUse = ref(false)
const isPaypalEnabled = ref(false)

const paymentMode = computed(() => {
  return process.env.NODE_ENV == 'production' ? 'production' : 'staging'
})
const stripeOptions = ref(null)

const squarePaymentMode = computed(() => {
  return props?.offer?.type === 'subscription' ? 'subscription' : ''
})

const isPaypalSubscription = computed(() => {
  return props.offer.type === 'subscription'
})

const currency = computed(() => {
  return props.offer.currency
})

const isZeroCheckout = computed(() => {
  return props.offer.amount === 0
})

onMounted(() => {
  stripeOptions.value = getStripeOptions()
})

function getStripeOptions() {
  const { amount, currency, type, trialDays, setupFee } = props.offer
  const currencyLower = currency.toLowerCase()
  if (type === 'subscription') {
    return {
      mode: 'subscription',
      amount:
        (trialDays && Number(trialDays)) > 0
          ? Number(setupFee ? setupFee : 0)
          : Number(amount) + Number(setupFee ? setupFee : 0),
      currency: currencyLower,
    }
  }
  const { couponData } = props
  const totalAmount = couponData?.isValidCode ? couponData.total : amount
  const options: any = {
    mode: totalAmount ? 'payment' : 'setup',
    currency: currencyLower,
  }
  if (totalAmount) options.amount = Number(totalAmount)
  return options
}

const customProviderOptions = computed(() => {
  const { amount, currency, type, paymentPriceId, paymentProductId } =
    props.offer

  if (type === 'subscription') {
    return {
      mode: 'subscription',
      amount: Number(amount),
      currency: currency.toLowerCase(),
      contact: {
        id: props.contact?.id,
        full_name: props.contact?.full_name_lower_case,
        email: props.contact?.email,
        phone: props.contact?.phone,
      },
      productDetails: [
        { productId: paymentProductId, priceId: paymentPriceId },
      ],
    }
  } else {
    return {
      mode: 'payment',
      amount: amount,
      currency: currency.toLowerCase(),
      contact: {
        id: props.contact?.id || '',
        full_name: props.contact?.full_name || '',
        email: props.contact?.email || '',
        phone: props.contact?.phone || '',
      },
    }
  }
})

const setErrorMsg = (msg: string) => {
  errorMsg.value = msg
}

const onPaymentCallback = (event) => {
  defaultProvider.value = event.provider
  if (event.type === UI_PAYMENT_ELEMENT_EVENT.READY) {
    isPaymentElementReady.value = true
    if (defaultProvider.value === 'custom-provider') {
      customProviderInUse.value = true
      props.togglePaymentFooter(true)
      // processPayment()
    } else if (defaultProvider.value === 'paypal') {
      showPaypalInPE.value = true
      isPaypalEnabled.value = true
    }
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.PREPROCESS) {
    processPayment(event.data, event.provider)
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.PROCESSING) {
    setErrorMsg('')
    if (event.provider !== 'paypal') {
      isProcessingPayment.value = event.value
    }
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.ERROR) {
    removeLocalStorageItem(`p_inp_${route.query.sourceId}`)
    if (event.message === 'Request failed with status code 429') {
      showRecaptcha.value = true
    } else {
      setErrorMsg(event.message)
    }
    isProcessingPayment.value = false
  } else if (event.type === UI_PAYMENT_ELEMENT_EVENT.SUCCESS) {
    removeLocalStorageItem(`p_inp_${route.query.sourceId}`)
    setErrorMsg('')
    isProcessingPayment.value = false
    paymentElementOptions.value = undefined
    isPaymentElementReady.value = false
    props.purchaseV2({
      ...event.data,
      orderId: orderData.value ? orderData.value?._id : event.data.orderId,
    })
  }
}

const onVerify = (response: any) => {
  if (!response) {
    reCaptchaError.value = t('payment.v2.recaptcha.verification_failed')
    return
  }
  reCaptchaError.value = ''
  reCaptchaToken.value = response
}

const fullName = computed(() =>
  props.contact?.first_name && props.contact?.last_name
    ? `${props.contact.first_name} ${props.contact.last_name}`
    : props.contact?.first_name || props.contact?.last_name || ''
)

const paymentElementContactPayload = computed(() => {
  return {
    email: props.contact?.email || '',
    phone: props.contact?.phone || '',
    name: fullName.value,
  }
})

__expose({
  processPayment,
})

async function placeOrder(provider?: string) {
  if (isProcessingOrder.value && provider !== 'paypal') return
  isProcessingOrder.value = true
  setErrorMsg('')
  try {
    const requestURL = `${config.paymentBaseUrl}/orders`
    const { locationId, contactId, sourceId, priceIds, offer, couponData } =
      props

    const requestBody = {
      altId: locationId,
      altType: 'location',
      contactId: contactId,
      source: {
        type: 'membership',
        id: sourceId, // offer id
        name: `${
          offer?.title ? offer?.title : `${locationId}:${contactId}`
        } - Payment`,
      },
      products: priceIds
        .split(',')
        .map((id) => ({
          id: id.trim(),
          qty: 1,
        }))
        .filter((product) => product.id),
      fingerprint: uuidv4(),
      trackingId: uuidv4(),
      captchaToken: reCaptchaToken.value,
    }

    if (couponData?.isValidCode) {
      requestBody.couponCode = couponData?.code
      requestBody.couponSessionId = uuidv4()
    }

    const { data } = await axios.post(requestURL, requestBody)
    orderData.value = data && data.order ? data.order : {}

    return data
  } catch (error) {
    if (error?.response?.status === 429) {
      return { reprocess: true }
    }
    const errorMsg = extractAPIErrorMsg(error)
    setErrorMsg(errorMsg)
  } finally {
    isProcessingOrder.value = false
  }
}

async function processPayment(paymentData?: any, provider?: string) {
  try {
    const isValid = await paymentElementRef.value.validatePayment(provider)
    if (!isValid) return
    const { order, reprocess } = await placeOrder()
    reCaptchaToken.value = undefined
    if (reprocess) {
      showRecaptcha.value = true
      isProcessingOrder.value = false
      return
    }
    if (order._id) {
      const sourceParams = {
        source: 'order',
        sourceId: order._id,
        traceId: order.traceId,
        captchaToken: reCaptchaToken.value,
        actions: provider === 'paypal' ? paymentData : {},
        provider,
      }
      // Convert props object to JSON string
      const parentProps = JSON.parse(base64ToUtf8(getLocalStorageItem(`p_inp`)))

      const propsJson = JSON.stringify({
        parentProps,
        sourceParams,
      })
      setLocalStorageItem(`p_inp_${sourceParams.sourceId}`, propsJson, 5)
      await paymentElementRef.value.confirmPayment(sourceParams)
    }
  } catch (error) {
    console.error(t('payment.v2.errors.processing_payment'), error)
  }
}

const onCallBack = () => {
  return `${window.location.origin}${route.fullPath}`
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", {
        class: _normalizeClass(["default-card-min-height", [
          customProviderInUse.value && isPaypalEnabled.value ? '' : 'card',
          !isPaymentElementReady.value ? 'flex items-center justify-center' : '',
        ]])
      }, [
        _createElementVNode("div", null, [
          (!isPaymentElementReady.value)
            ? (_openBlock(), _createBlock(UISpinner, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass([isPaymentElementReady.value ? '' : 'hidden', "text-center"])
          }, [
            _createVNode(_unref(UIPaymentElement), {
              ref_key: "paymentElementRef",
              ref: paymentElementRef,
              env: paymentMode.value,
              id: "payment-element",
              altId: __props.locationId,
              altType: "location",
              liveMode: isLivePayment.value,
              debug: false,
              showModeBadge: true,
              stripeOptions: stripeOptions.value,
              customProviderProps: customProviderOptions.value,
              onPaymentCallback: onPaymentCallback,
              mode: squarePaymentMode.value,
              "callback-url": onCallBack(),
              showPaypal: showPaypalInPE.value,
              paymentProps: {
                currency: currency.value.value,
                mode: isPaypalSubscription.value.valueOf()
                  ? 'subscription'
                  : 'onetime',
                errMsg: errorMsg.value,
                isZeroCheckout: isZeroCheckout.value.valueOf(),
              },
              disablePaypal: disablePaypalButton.value,
              contact: paymentElementContactPayload.value
            }, null, 8, ["env", "altId", "liveMode", "stripeOptions", "customProviderProps", "mode", "callback-url", "showPaypal", "paymentProps", "disablePaypal", "contact"]),
            (customProviderInUse.value)
              ? (_openBlock(), _createBlock(_unref(UIButton), {
                  key: 0,
                  id: "custom-provider-payment-button",
                  onClick: processPayment,
                  type: "primary",
                  class: "my-8"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('payment.v2.custom_provider.pay_button')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ], 2),
      (showRecaptcha.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(Recaptcha, {
              onExpired: _cache[0] || (_cache[0] = () => (reCaptchaToken.value = undefined)),
              onVerify: onVerify
            }),
            (reCaptchaError.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(reCaptchaError.value), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (errorMsg.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _unref(PaymentWarningIcon),
              alt: _unref(t)('payment.v2.warning_icon_alt')
            }, null, 8, _hoisted_4),
            _createElementVNode("span", _hoisted_5, _toDisplayString(errorMsg.value), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})