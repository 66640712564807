import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full"
}
const _hoisted_2 = { class: "v2-offer-title font-family-inter pb-5" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 2,
  class: "mt-3"
}
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = {
  key: 1,
  class: "font-medium"
}
const _hoisted_7 = {
  key: 0,
  class: "flex flex-row space-x-4 py-2"
}
const _hoisted_8 = { class: "flex justify-center items-center" }
const _hoisted_9 = { class: "text-sm px-2" }
const _hoisted_10 = { class: "flex justify-center items-center" }
const _hoisted_11 = { class: "text-sm px-2" }
const _hoisted_12 = { class: "flex flex-col items-start" }
const _hoisted_13 = { class: "w-full" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "w-full flex items-center justify-between px-2" }
const _hoisted_18 = { class: "flex items-center" }
const _hoisted_19 = ["value"]
const _hoisted_20 = ["src"]
const _hoisted_21 = { class: "flex flex-col w-2/3 justify-end items-center text-sm text-gray-700 font-normal" }
const _hoisted_22 = { class: "w-full flex justify-end" }
const _hoisted_23 = { class: "w-full flex justify-end" }
const _hoisted_24 = { key: 2 }
const _hoisted_25 = {
  key: 0,
  class: "text-left text-red-500 text-sm px-2 pb-2"
}
const _hoisted_26 = {
  key: 1,
  class: "text-center secure-text font-family-inter text-gray-400 pt-1 pb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIModalHeader = _resolveComponent("UIModalHeader")!
  const _component_UITextXsMedium = _resolveComponent("UITextXsMedium")!
  const _component_UIDisplaySmMedium = _resolveComponent("UIDisplaySmMedium")!
  const _component_UITextSmMedium = _resolveComponent("UITextSmMedium")!
  const _component_UIButton = _resolveComponent("UIButton")!
  const _component_CouponFormModal = _resolveComponent("CouponFormModal")!
  const _component_UITextSmRegular = _resolveComponent("UITextSmRegular")!
  const _component_Ticket02Icon = _resolveComponent("Ticket02Icon")!
  const _component_stripe_card_element = _resolveComponent("stripe-card-element")!
  const _component_PaymentV2 = _resolveComponent("PaymentV2")!
  const _component_UIModal = _resolveComponent("UIModal")!

  return (_openBlock(), _createBlock(_component_UIModal, {
    show: _ctx.openModal,
    width: 400
  }, {
    header: _withCtx(() => [
      _createVNode(_component_UIModalHeader, {
        type: "success",
        onClose: _ctx.closeModal,
        id: "modal-header",
        icon: _ctx.showPaymentConfirmation ? _ctx.checkIcon : _ctx.icon,
        title: _ctx.modalTitleAndDescription.title,
        description: _ctx.modalTitleAndDescription.description
      }, null, 8, ["onClose", "icon", "title", "description"]),
      (!_ctx.showPaymentConfirmation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.offer.title), 1),
            _createElementVNode("div", null, [
              (_ctx.offer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_UITextXsMedium, { class: "amount-paid-text text-gray-700 font-family-inter" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(this.t('payment.amount_to_be_paid')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_UIDisplaySmMedium, { class: "price-text text-gray-900 font-family-inter" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(`${_ctx.fetchCurrencySymbol(_ctx.offer.currency)}${_ctx.localCouponData ? Number(_ctx.localCouponData?.total).toFixed(2) :
                    Number(_ctx.actualOfferPrice).toFixed(2)
                  }`), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    (_ctx.couponsFeatureFlag && _ctx.showCoupon)
                      ? (_openBlock(), _createBlock(_component_UIButton, {
                          key: 0,
                          id: "have-coupon-button",
                          text: true,
                          onClick: _ctx.toggleCouponModal
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_UITextSmMedium, {
                              class: _normalizeClass(_ctx.localCouponData && _ctx.localCouponData.isValidCode
                ? 'text-red-600'
                : ''
                )
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(!_ctx.localCouponData || !_ctx.localCouponData.isValidCode
                    ? this.t('payment.coupon.have_coupon')
                    : this.t('payment.coupon.remove_applied')), 1)
                              ]),
                              _: 1
                            }, 8, ["class"])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.couponsFeatureFlag && _ctx.showCoupon)
                      ? (_openBlock(), _createBlock(_component_CouponFormModal, {
                          key: 1,
                          isCouponModalActive: _ctx.isCouponModalActive,
                          offer: _ctx.offer,
                          appliedCouponData: _ctx.appliedCouponData,
                          localCouponData: _ctx.localCouponData,
                          currency: _ctx.fetchCurrencySymbol(_ctx.offer.currency),
                          onToggleCouponModal: _ctx.toggleCouponModal,
                          onUpdateCouponData: _ctx.updateCouponData
                        }, null, 8, ["isCouponModalActive", "offer", "appliedCouponData", "localCouponData", "currency", "onToggleCouponModal", "onUpdateCouponData"]))
                      : _createCommentVNode("", true),
                    (_ctx.localCouponData && _ctx.localCouponData.isValidCode)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_UITextSmRegular, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(this.t('payment.coupon.discount_applied')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_UITextSmMedium, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(`- ${_ctx.fetchCurrencySymbol(_ctx.offer.currency)}${Number(_ctx.localCouponData.discount).toFixed(2)
                    }`), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", {
                            class: _normalizeClass(["grid w-fit grid-cols-2 coupon-tag-layout items-center border border-green-500 text-green-500 py-1 px-2 mt-2 bg-green-50", _ctx.localCouponData.code?.length > 25
                  ? 'rounded-lg'
                  : 'rounded-full'
                  ])
                          }, [
                            _createVNode(_component_Ticket02Icon, { class: "w-4 h-4 mr-1" }),
                            _createVNode(_component_UITextSmRegular, { class: "break-all uppercase text-xs font-semibold" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.localCouponData.code), 1)
                              ]),
                              _: 1
                            })
                          ], 2)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(this.t('payment.enter_card_details')), 1))
            ]),
            (
          _ctx.paymentMethods && _ctx.paymentMethods.length && _ctx.offerVersion === 'v1'
        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      class: "cursor-pointer",
                      type: "radio",
                      value: "savedCards",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chargeCardType) = $event)),
                      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.errMsg = ''))
                    }, null, 544), [
                      [_vModelRadio, _ctx.chargeCardType]
                    ]),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(this.t('payment.saved_cards')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      class: "cursor-pointer",
                      type: "radio",
                      value: "newCard",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.chargeCardType) = $event)),
                      onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.errMsg = '')),
                      size: "default"
                    }, null, 544), [
                      [_vModelRadio, _ctx.chargeCardType]
                    ]),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(this.t('payment.new_card')), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex", _ctx.showPaymentConfirmation ? 'pt-5' : ''])
      }, [
        _createVNode(_component_UIButton, {
          type: "default",
          id: "modal-ok",
          class: "w-1/2 mr-1",
          onClick: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(this.t('common.button.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_UIButton, {
          loading: _ctx.loadingPayButton,
          type: "primary",
          id: "modal-ok",
          disabled: 
            _ctx.loadingPayButton ||
            _ctx.disableCheckoutBtn ||
            (_ctx.offerVersion === 'v1' &&
              _ctx.chargeCardType === 'savedCards' &&
              !_ctx.selectedSavedCard)
            || _ctx.hidePaymentFooter
          ,
          class: "w-1/2",
          onClick: _ctx.onConfirmPayment
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(!_ctx.showPaymentConfirmation ? this.t('payment.confirm_payment') : this.t('payment.dashboard_button')), 1)
          ]),
          _: 1
        }, 8, ["loading", "disabled", "onClick"])
      ], 2)
    ]),
    default: _withCtx(() => [
      (!_ctx.showPaymentConfirmation)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["inline-block rounded-lg text-left overflow-hidden shadow pt-2 mt-8 lg:align-middle md:align-middle w-full offer-modal pb-5", _ctx.showPaymentConfirmation
        ? 'dashboard-modal'
        : _ctx.paymentMethods && _ctx.paymentMethods.length > 2
          ? 'payment-modal h-auto lg:h-[32rem]'
          : 'offer-modal'
        ])
          }, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", {
                class: _normalizeClass(["w-full text-left", _ctx.chargeCardType === 'savedCards' && _ctx.paymentMethods.length > 3
          ? 'h-48 overflow-y-scroll'
          : ''
          ])
              }, [
                _createElementVNode("div", _hoisted_13, [
                  (_ctx.showPaymentConfirmation)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                    : _createCommentVNode("", true),
                  (_ctx.chargeCardType === 'savedCards' && _ctx.offerVersion === 'v1')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethods, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.id,
                            class: _normalizeClass(["rounded-md px-3 py-2 my-2 flex items-center justify-start space-x-4 cursor-pointer border hover:border-blue-300", _ctx.selectedSavedCard === item.id &&
                    'border-2 border-blue-500 bg-gray-200'
                    ]),
                            onClick: ($event: any) => (_ctx.selectSavedCard(item.id))
                          }, [
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "cursor-pointer",
                                  type: "radio",
                                  value: item.id,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedSavedCard) = $event)),
                                  size: "default"
                                }, null, 8, _hoisted_19), [
                                  [_vModelRadio, _ctx.selectedSavedCard]
                                ]),
                                _createElementVNode("img", {
                                  src: item.card &&
                        item.card.brand &&
                        _ctx.getCardIcon(item.card.brand)
                        ,
                                  class: "w-12 pl-2",
                                  alt: "Card"
                                }, null, 8, _hoisted_20)
                              ]),
                              _createElementVNode("div", _hoisted_21, [
                                _createElementVNode("div", _hoisted_22, [
                                  _cache[8] || (_cache[8] = _createElementVNode("span", null, "**** **** ****", -1)),
                                  _createTextVNode(" " + _toDisplayString(item.card.last4), 1)
                                ]),
                                _createElementVNode("span", _hoisted_23, _toDisplayString(item.card &&
                        `${item.card.brand
                          .substring(0, 1)
                          .toUpperCase()}${item.card.brand.substring(
                            1,
                            item.card.brand.length
                          )}`), 1)
                              ])
                            ])
                          ], 10, _hoisted_16))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                        (_ctx.offerVersion === 'v1')
                          ? (_openBlock(), _createBlock(_component_stripe_card_element, {
                              key: 0,
                              location: _ctx.location,
                              stripeCardError: _ctx.errMsg,
                              offer: _ctx.offer,
                              isUpsell: _ctx.isUpsell,
                              onStripeCardError: _cache[5] || (_cache[5] = (value) => (_ctx.errMsg = value)),
                              onStripeElements: _cache[6] || (_cache[6] = (value) => (_ctx.stripeElements = value)),
                              onSetStripe: _cache[7] || (_cache[7] = (value) => (_ctx.stripe = value))
                            }, null, 8, ["location", "stripeCardError", "offer", "isUpsell"]))
                          : _createCommentVNode("", true),
                        (_ctx.offerVersion === 'v2')
                          ? (_openBlock(), _createBlock(_component_PaymentV2, {
                              key: _ctx.updateKey,
                              offer: _ctx.offer,
                              locationId: _ctx.locationId,
                              contactId: _ctx.contact.id,
                              contact: _ctx.contact,
                              sourceId: _ctx.offer.id,
                              priceIds: _ctx.offer.paymentPriceId,
                              couponData: _ctx.localCouponData,
                              size: 'sm',
                              purchaseV2: _ctx.purchaseV2,
                              closeModal: _ctx.closeModal,
                              isLivePayment: _ctx.isLivePayment,
                              togglePaymentFooter: _ctx.togglePaymentFooter,
                              ref: "childRef"
                            }, null, 8, ["offer", "locationId", "contactId", "contact", "sourceId", "priceIds", "couponData", "purchaseV2", "closeModal", "isLivePayment", "togglePaymentFooter"]))
                          : _createCommentVNode("", true)
                      ]))
                ])
              ], 2),
              (_ctx.errMsg)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.errMsg), 1))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : _createCommentVNode("", true),
      (!_ctx.showPaymentConfirmation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(this.t('payment.secure_payment_message')), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["show"]))
}