<template>
  <div class="relative cursor-pointer group">
    <!-- Stacked cards -->
    <div
      v-if="offer?.products && offer.products?.length > 1"
      class="absolute top-0 w-[102%] h-full bg-red border-2 border-slate-200 rounded-lg z-10 transition-transform duration-300 ease-in-out group-hover:translate-x-0"
    ></div>
    <div
      v-if="offer?.products && offer.products?.length > 1"
      class="absolute top-0 w-[104%] h-full bg-red border-2 border-slate-200 rounded-lg z-20 transition-transform duration-300 ease-in-out group-hover:shadow-xl group-hover:translate-x-1"
    ></div>

    <div
      class="absolute top-0 z-30 border-2 shadow-sm bg-white border-slate-200 p-4 rounded-lg w-[100%] h-full transition-transform duration-300 ease-in-out group-hover:-translate-x-1"
      :class="newMobileScreens ? 'h-full' : ''"
    >
      <div
        @click="openUpsell"
        class="w-full h-full z-10 flex justify-between flex-col"
        :class="[
          newMobileScreens
            ? !notInCarousel
              ? 'shadow-none'
              : 'shadow rounded'
            : '',
        ]"
      >
        <div
          class="flex justify-center items-center relative rounded-lg"
          :class="
            newMobileScreens && !notInCarousel
              ? 'shadow rounded-lg'
              : notInCarousel
              ? ''
              : ''
          "
        >
          <div
            v-if="!newMobileScreens"
            class="rounded-lg block absolute top-0 left-0 z-1 w-full h-full"
            :style="{ backdropFilter: 'blur(16px)' }"
          ></div>
          <img
            class="w-full h-full aspect-video object-cover rounded-lg"
            :src="posterImage"
            :alt="t('product.neo.upsell.imageAlt')"
          />
          <div
            v-if="newMobileScreens"
            class="absolute text-xs rounded text-white px-1 right-0 bottom-0 mr-2 mb-2 z-1"
            :class="
              newMobileScreens ? getMobileTheme.primary : 'bg-nova-blue-600'
            "
          >
            <span
              id="offer-price-display-override"
              v-if="offer.priceDisplayOverride"
            >
              {{ offer.priceDisplayOverride }}
            </span>
            <span id="offer-amount" v-else>
              {{ fetchCurrencySymbol(offer.currency)
              }}{{ Number(offer.amount).toFixed(2) }}
            </span>
          </div>

          <BaseIcon
            name="lockfilled"
            hwClass="w-16 h-16"
            class="absolute w-4 h-4 lg:w-12 lg:h-12 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
            :style="{ color: '#fff' }"
          />
        </div>
        <div
          class="absolute top-5 right-5 m-2 lg:px-4 p-2 rounded-full text-white font-semibold bg-warning-500"
          v-if="offer.products && offer.products.length !== 1"
        >
          {{ t('product.neo.upsell.bundle') }}
        </div>
        <div
          class="product-details lg:pb-2 lg:mb-1"
          :class="newMobileScreens ? '' : 'h-44'"
        >
          <div
            class="tracking-wide text-black font-medium text-lg"
            :class="newMobileScreens ? !notInCarousel && 'text-center' : ''"
          >
            {{ courseData.title || '' }}
          </div>

          <div
            class="text-gray-700 mt-2 leading-normal font-normal text-sm"
            v-if="!newMobileScreens"
          >
            {{ courseData.description || '' }}
          </div>
        </div>

        <div
          v-if="!newMobileScreens"
          class="flex items-center justify-between w-full self-end"
        >
          <div class="flex items-center">
            <div id="instructor-headshot">
              <img
                class="rounded-lg aspect-square object-cover w-12"
                :src="userAvatarImage"
                :alt="t('product.neo.upsell.instructor.imageAlt')"
              />
            </div>
            <div
              id="instructor-name"
              class="text-sm font-semibold text-black pl-2"
            >
              {{
                truncateText(
                  courseData.instructorName ||
                    t('product.neo.upsell.instructor.defaultName'),
                  instructorNameLimit
                )
              }}
            </div>
          </div>
          <Badge
            id="bundled-card-button"
            variant="primary"
            size="medium"
            class="max-w-32"
          >
            <span
              class="overflow-hidden text-ellipsis whitespace-nowrap block"
              :title="offer.priceDisplayOverride"
              id="offer-price-display-override"
              v-if="offer.priceDisplayOverride"
            >
              {{ offer.priceDisplayOverride }}
            </span>
            <span id="offer-amount" v-else>
              {{ fetchCurrencySymbol(offer.currency) }}
              {{ '' }}
              {{ Number(offer.amount).toFixed(2) }}
            </span>
          </Badge>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  defaultEmptyImage,
  userAvatarImage,
  INSTRUCTOR_NAME_MAX_LENGTH,
  DESCRIPTION_LIMIT,
} from '../../helper/constants'
import { getMobileTheme, getTextContentFromHTML } from '@/helper'
import UpsellModal from '@/components/common/UpsellModal.vue'
import BaseIcon from '@/components/svgicons/BaseIcon.vue'
import {
  replaceBucketUrlWithCdnUrl,
  fetchCurrencySymbol,
  truncateText,
} from '@/helper/filter'
import Badge from '@/components/neo-classic-theme/common/Badge.vue'

export default defineComponent({
  components: { UpsellModal, BaseIcon, Badge },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  props: {
    upsellId: {
      type: String,
    },
    offer: {
      type: Object,
    },
    purchasedProductIds: {
      type: Array,
    },
    notInCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userAvatarImage,
      defaultEmptyImage,
      isMobile: false,
      instructorNameLimit: INSTRUCTOR_NAME_MAX_LENGTH,
    }
  },
  computed: {
    courseData(): any {
      if (this.offer?.products?.length === 1) {
        const product = this.offer.products[0]
        return {
          title: this.truncateText(product.title),
          posterImage: product.posterImage,
          description: this.truncateText(
            getTextContentFromHTML(product.description),
            DESCRIPTION_LIMIT
          ),
          instructorName:
            product.customizations?.instructorName ||
            this.t('product.neo.upsell.instructor.defaultName'),
          instructorHeadshot: product.customizations?.instructorHeadshot,
        }
      } else {
        return {
          title: this.truncateText(this.offer?.title),
          posterImage: this.offer?.posterImage,
          description: this.truncateText(
            getTextContentFromHTML(this.offer?.description),
            DESCRIPTION_LIMIT
          ),
          instructorName: this.t('product.neo.upsell.instructor.multiple'),
          instructorHeadshot: null,
        }
      }
    },
    posterImage(): string {
      return replaceBucketUrlWithCdnUrl(
        this.courseData.posterImage || defaultEmptyImage
      )
    },
    newMobileScreens(): boolean {
      return this.$store.getters.newMobileScreens
    },
    getMobileTheme() {
      return getMobileTheme()
    },
  },
  methods: {
    openUpsell() {
      if (this.newMobileScreens) {
        this.$router.push({
          name: 'upsell-bundle',
          params: {
            offerId: this.offer.id,
            upsellId: this.upsellId,
          },
        })
        return
      }
      this.handleUpsellCheckout()
    },
    handleUpsellCheckout() {
      this.emitter.emit('handleUpsellCheckout', this.upsellId)
    },
    fetchCurrencySymbol,
    truncateText,
  },
})
</script>
<style scoped>
.product-title {
  word-break: break-word;
}

.product-image > img {
  aspect-ratio: 16/9;
  object-fit: contain;
}

.stack-1 {
  top: 4px;
  left: 4px;
  z-index: 3;
}

.stack-2 {
  top: 8px;
  left: 8px;
  z-index: 2;
}
</style>
