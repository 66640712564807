<template>
  <div>
    <img
      :src="url"
      :class="mergedImageClass"
      :style="imageStyle"
      @click="toggleModal"
      class="cursor-pointer"
      :alt="attrs.alt || t('common.image.defaultAlt')"
      v-bind="attrs"
    />
    <Teleport to="body">
      <Transition name="fade">
        <div
          v-if="showModal && preview"
          :id="modalId"
          class="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center transition-opacity duration-300 z-[2000]"
          @click="showModal = false"
        >
          <button
            :id="closeButtonId"
            @click="showModal = false"
            class="absolute top-4 right-4 text-white text-4xl z-[2001]"
            :aria-label="t('common.image.closePreview')"
          >
            &times;
          </button>
          <div
            :id="modalContentId"
            class="relative rounded-lg max-w-4xl w-4/5 flex justify-center items-center"
            @click.stop
          >
            <img
              :id="previewImageId"
              :src="url"
              :class="mergedPreviewClass"
              :style="previewStyle"
              class="w-full h-auto max-h-[90vh]"
              :alt="attrs.alt || t('common.image.defaultAlt')"
            />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, useAttrs } from 'vue'
import { twMerge } from 'tailwind-merge'
import { useI18n } from 'vue-i18n'

interface Props {
  url: string
  shape: 'square' | 'rounded' | 'circle'
  preview: boolean
  imageClass?: string
  imageStyle?: string
  previewClass?: string
  previewStyle?: string
  imgClass?: string
}

const props = defineProps<Props>()

const showModal = ref(false)

const imageClass = computed(() => {
  switch (props.shape) {
    case 'rounded':
      return 'rounded-lg'
    case 'circle':
      return 'rounded-full'
    default:
      return ''
  }
})

const attrs = useAttrs()

const mergedImageClass = computed(() => {
  return twMerge(imageClass.value, props.imageClass, props.imgClass)
})

const mergedPreviewClass = computed(() => {
  return twMerge(props.previewClass)
})

const imageStyle = computed(() => props.imageStyle || '')
const previewStyle = computed(() => props.previewStyle || '')

const toggleModal = () => {
  if (props.preview) {
    showModal.value = !showModal.value
  }
}

const imageId = 'image-component'
const modalId = 'image-modal'
const modalContentId = 'modal-content'
const closeButtonId = 'close-button'
const previewImageId = 'preview-image'

const { t } = useI18n()
</script>

<style scoped>
/* Add any additional styles here */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
