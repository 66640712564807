import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-end w-full" }
const _hoisted_2 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "grid grid-cols-2 w-full coupon-tag-layout items-center mt-2" }
const _hoisted_5 = { key: 1 }

import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Coupons } from '@/helper/restAgent'
import { currency } from '@/helper/constants'
import { OfferType } from '@/models/Offer'
import UISpinner from '@/components/common/UISpinner.vue'
import {
  UIInput,
  UIButton,
  UITextSmMedium,
  UITextSmRegular,
  UIFormItem,
} from '@gohighlevel/ghl-ui'
import { XCloseIcon } from '@gohighlevel/ghl-icons/24/outline'


export default /*@__PURE__*/_defineComponent({
  __name: 'CouponForm',
  props: {
  offer: {
    type: Object,
    default: () => ({}),
  },
  borders: {
    type: Boolean,
    default: true,
  },
  showLabel: {
    type: Boolean,
    default: false,
  },
  appliedData: {
    type: Object,
    default: null,
  },
},
  emits: ['updateCouponData'],
  setup(__props, { emit: __emit }) {

const props = __props

const { t } = useI18n()

const couponData = ref<any>({})
const couponCode = ref<string>('')
const isVerifying = ref<boolean>(false)
const store = useStore()

const emit = __emit

const showCoupons = computed(() => {
  return props.offer.type === OfferType.oneTime && props.offer.coupons
})

const locationId = computed(() => {
  return props.offer.locationId
})

const localCouponData = computed(() => {
  if (props.appliedData) return props.appliedData
  return couponData.value
})

function getCurrencySymbol(currencyCode: string) {
  return currency[currencyCode].symbol
}

async function verifyCoupon() {
  try {
    isVerifying.value = true
    const res = await Coupons.verify({
      altId: locationId.value,
      altType: 'location',
      code: couponCode.value,
      products: [
        {
          id: props.offer.paymentProductId,
          priceId: props.offer.paymentPriceId,
          qty: 1,
        },
      ],
    })
    const { isValidCode } = res

    if (!isValidCode) couponCode.value = ''
    couponData.value = {
      ...res,
    }
    emit('updateCouponData', { ...res })
  } catch (error) {
    console.log('Error while verifying coupon: ', error)
  } finally {
    isVerifying.value = false
  }
}

function removeCoupon() {
  couponCode.value = ''
  couponData.value = {}
  emit('updateCouponData', null)
}

return (_ctx: any,_cache: any) => {
  return (showCoupons.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["mb-2", __props.borders ? 'border-b border-t py-4' : ''])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(UIFormItem), {
            label: _unref(t)('checkout.coupon.label'),
            class: "w-full",
            showLabel: __props.showLabel,
            showFeedback: false
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(UIInput), {
                id: "coupon-code",
                modelValue: couponCode.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((couponCode).value = $event)),
                "aria-label": "couponCode",
                class: "w-full mr-2",
                status: 
            localCouponData.value.code && !localCouponData.value.isValidCode
              ? 'error'
              : null
          ,
                placeholder: _unref(t)('checkout.coupon.placeholder')
              }, null, 8, ["modelValue", "status", "placeholder"])
            ]),
            _: 1
          }, 8, ["label", "showLabel"]),
          _createVNode(_unref(UIButton), {
            id: "coupon-apply",
            disabled: !couponCode.value || isVerifying.value,
            style: {"background-color":"var(--n-color)"},
            class: "hover:text-blue-600",
            onClick: verifyCoupon,
            type: "tertiary"
          }, {
            default: _withCtx(() => [
              (isVerifying.value)
                ? (_openBlock(), _createBlock(UISpinner, {
                    key: 0,
                    color: "#ffffff",
                    size: "small"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_unref(UITextSmMedium), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('checkout.coupon.apply')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        (localCouponData.value.isValidCode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(UITextSmMedium), null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('checkout.label.subtotal')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(UITextSmMedium), null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(`${getCurrencySymbol(__props.offer.currency)}${localCouponData.value.subtotal}`), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass(["grid w-fit grid-cols-2 coupon-tag-layout items-center border border-green-500 text-green-500 py-1 px-2 bg-green-50 mr-2", 
            localCouponData.value.code?.length > 25 ? 'rounded-lg' : 'rounded-full'
          ])
                }, [
                  _createVNode(_unref(UITextSmRegular), { class: "break-all uppercase text-xs font-semibold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(localCouponData.value.code), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(UIButton), {
                    onClick: removeCoupon,
                    text: true,
                    class: "ml-1 text-green-600 cursor-pointer",
                    type: "default",
                    id: "remove-coupon-code"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(XCloseIcon), { class: "w-4 h-4 hover:text-red-600" })
                    ]),
                    _: 1
                  })
                ], 2),
                _createVNode(_unref(UITextSmMedium), { class: "text-gray-500" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(`- ${getCurrencySymbol(__props.offer.currency)}${localCouponData.value.discount}`), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : (localCouponData.value.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_unref(UITextSmRegular), { class: "text-red-600 mt-1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(localCouponData.value.errorMessage), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})