<template>
  <div
    v-if="showCoupons"
    class="mb-2"
    :class="borders ? 'border-b border-t py-4' : ''"
  >
    <div class="flex items-end w-full">
      <UIFormItem
        :label="t('checkout.coupon.label')"
        class="w-full"
        :showLabel="showLabel"
        :showFeedback="false"
      >
        <UIInput
          id="coupon-code"
          v-model="couponCode"
          aria-label="couponCode"
          class="w-full mr-2"
          :status="
            localCouponData.code && !localCouponData.isValidCode
              ? 'error'
              : null
          "
          :placeholder="t('checkout.coupon.placeholder')"
        />
      </UIFormItem>
      <UIButton
        id="coupon-apply"
        :disabled="!couponCode || isVerifying"
        style="background-color: var(--n-color)"
        class="hover:text-blue-600"
        @click="verifyCoupon"
        type="tertiary"
      >
        <UISpinner color="#ffffff" v-if="isVerifying" size="small" />
        <UITextSmMedium>{{ t('checkout.coupon.apply') }}</UITextSmMedium>
      </UIButton>
    </div>
    <div v-if="localCouponData.isValidCode" class="mt-2">
      <div class="flex items-center justify-between">
        <UITextSmMedium>{{ t('checkout.label.subtotal') }}</UITextSmMedium>
        <UITextSmMedium>
          {{
            `${getCurrencySymbol(offer.currency)}${localCouponData.subtotal}`
          }}
        </UITextSmMedium>
      </div>
      <div class="grid grid-cols-2 w-full coupon-tag-layout items-center mt-2">
        <div
          class="grid w-fit grid-cols-2 coupon-tag-layout items-center border border-green-500 text-green-500 py-1 px-2 bg-green-50 mr-2"
          :class="
            localCouponData.code?.length > 25 ? 'rounded-lg' : 'rounded-full'
          "
        >
          <UITextSmRegular class="break-all uppercase text-xs font-semibold">
            {{ localCouponData.code }}
          </UITextSmRegular>
          <UIButton
            @click="removeCoupon"
            :text="true"
            class="ml-1 text-green-600 cursor-pointer"
            type="default"
            id="remove-coupon-code"
          >
            <XCloseIcon class="w-4 h-4 hover:text-red-600" />
          </UIButton>
        </div>
        <UITextSmMedium class="text-gray-500">
          {{
            `- ${getCurrencySymbol(offer.currency)}${localCouponData.discount}`
          }}
        </UITextSmMedium>
      </div>
    </div>
    <div v-else-if="localCouponData.errorMessage">
      <UITextSmRegular class="text-red-600 mt-1">
        {{ localCouponData.errorMessage }}
      </UITextSmRegular>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { Coupons } from '@/helper/restAgent'
import { currency } from '@/helper/constants'
import { OfferType } from '@/models/Offer'
import UISpinner from '@/components/common/UISpinner.vue'
import {
  UIInput,
  UIButton,
  UITextSmMedium,
  UITextSmRegular,
  UIFormItem,
} from '@gohighlevel/ghl-ui'
import { XCloseIcon } from '@gohighlevel/ghl-icons/24/outline'

const props = defineProps({
  offer: {
    type: Object,
    default: () => ({}),
  },
  borders: {
    type: Boolean,
    default: true,
  },
  showLabel: {
    type: Boolean,
    default: false,
  },
  appliedData: {
    type: Object,
    default: null,
  },
})

const { t } = useI18n()

const couponData = ref<any>({})
const couponCode = ref<string>('')
const isVerifying = ref<boolean>(false)
const store = useStore()

const emit = defineEmits(['updateCouponData'])

const showCoupons = computed(() => {
  return props.offer.type === OfferType.oneTime && props.offer.coupons
})

const locationId = computed(() => {
  return props.offer.locationId
})

const localCouponData = computed(() => {
  if (props.appliedData) return props.appliedData
  return couponData.value
})

function getCurrencySymbol(currencyCode: string) {
  return currency[currencyCode].symbol
}

async function verifyCoupon() {
  try {
    isVerifying.value = true
    const res = await Coupons.verify({
      altId: locationId.value,
      altType: 'location',
      code: couponCode.value,
      products: [
        {
          id: props.offer.paymentProductId,
          priceId: props.offer.paymentPriceId,
          qty: 1,
        },
      ],
    })
    const { isValidCode } = res

    if (!isValidCode) couponCode.value = ''
    couponData.value = {
      ...res,
    }
    emit('updateCouponData', { ...res })
  } catch (error) {
    console.log('Error while verifying coupon: ', error)
  } finally {
    isVerifying.value = false
  }
}

function removeCoupon() {
  couponCode.value = ''
  couponData.value = {}
  emit('updateCouponData', null)
}
</script>
<style>
.coupon-tag-layout {
  grid-template-columns: 1fr auto;
}
</style>
