import {
  CLIENTPORTAL_GTAGID,
  MEMBERSHIP_GTAGID,
  NEW_MOBILE_UI_DEFAULT_ACCENT_COLOR,
  NEW_MOBILE_UI_DEFAULT_PRIAMRY_COLOR,
  NEW_MOBILE_UI_DEFAULT_SECONDARY_COLOR,
  defaultIcon,
  docIcon,
  jpegIcon,
  jpgIcon,
  mp3Icon,
  mp4Icon,
  newDocIcon,
  newPdfLogo,
  pngIcon,
  txtIcon,
  xlsxIcon,
} from './constants'
import CategoryTreeNode, {
  CategoryTreeNodeType,
} from '../models/CategoryTreeNode'
import {
  MAXIMUM_CHARACTER_LENGTH_BEFORE_READMORE,
  MAX_COMMENT_LENGTH,
  SLICED_TITLE_LENGTH,
  defaultAssessmentImage,
  defaultPosterImage,
  videoFileTypeMapping,
} from './constants'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import Post, { PostContentType } from '../models/Post'
import ProductCustomization, {
  HeroSpacing,
  HeroTextAlignment,
  Instructor,
} from '@/models/ProductCustomization'
import { bootstrap, event, setOptions } from 'vue-gtag'

import Category from '../models/Category'
import LocationService from '@/services/LocationService'
import LoginService from '@/services/LoginService'
import { fetchCat } from './permission.helper'
import { isMobileTablet } from './device_info'
import restAgent from './restAgent'
import router from '../router'
import store from '@/store'
import { useRoute } from 'vue-router'

const nonAcceptableFileTypes = new Set([
  'application/x-msdownload',
  // 'application/zip',
  'dmg',
  'exe',
  // 'zip',
  'bat',
  'apk',
  'csh',
  'ipa',
  'msi',
  'reg',
  'jar',
  'cmd',
  'bin',
  'com',
])

export function createCategoryPostTree(
  parentNodeId: string | null,
  categories: Array<Category>
): Array<CategoryTreeNode> {
  const childs = categories
    .filter((category: Category) => category.parentCategory === parentNodeId)
    .sort((a, b) => a.sequenceNo - b.sequenceNo)

  const nodes = childs.map((c: Category) => {
    const node = new CategoryTreeNode(
      c,
      CategoryTreeNodeType.Category,
      parentNodeId || CategoryTreeNode.ROOT_PARENT_NODE
    )
    const subCategoryNodes = createCategoryPostTree(node.id, categories)

    const postsInCategory = c.posts || []
    const postNodes = postsInCategory.map(
      (p: Post) => new CategoryTreeNode(p, CategoryTreeNodeType.Post, node.id)
    )

    node.childs = [...subCategoryNodes, ...postNodes].sort(
      (a, b) => a.sequenceNo - b.sequenceNo
    )

    return node
  })

  return nodes
}

export function findLastCompletedPost(
  tree: Array<CategoryTreeNode>,
  completedPosts: Set<string>
): CategoryTreeNode | undefined {
  for (let i = tree.length - 1; i >= 0; i--) {
    const node = tree[i]

    if (
      node.type === CategoryTreeNodeType.Post &&
      completedPosts.has(node.id)
    ) {
      return node
    } else {
      const lastCompletedPostInSubtree = findLastCompletedPost(
        node.childs,
        completedPosts
      )
      if (lastCompletedPostInSubtree) {
        return lastCompletedPostInSubtree
      }
    }
  }
}

export function findFirstUncompletedPost(
  tree: Array<CategoryTreeNode>,
  completedPosts: Set<string>
): CategoryTreeNode | undefined {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]

    if (
      node.type === CategoryTreeNodeType.Post &&
      !completedPosts.has(node.id)
    ) {
      return node
    } else {
      const firstUncompletedPostInSubtree = findFirstUncompletedPost(
        node.childs,
        completedPosts
      )

      if (firstUncompletedPostInSubtree) {
        return firstUncompletedPostInSubtree
      }
    }
  }
}

export function unwrapCategoryTree(tree: any, isSubCategory: boolean) {
  if (!tree?.node) return []
  const categoryNodes = []
  let nodes = {
    post: [],
    categoryTitle: tree.node.title,
    isSubCategory: isSubCategory,
    available: tree.node.available,
  }
  for (const node of tree.childs) {
    if (node.type === CategoryTreeNodeType.Post) {
      nodes['post'].push(node)
    } else if (node.type === CategoryTreeNodeType.Category) {
      if (nodes.post.length) {
        categoryNodes.push(nodes)
        nodes = {
          post: [],
          categoryTitle: tree.node.title,
          isSubCategory: isSubCategory,
          available: tree.node.available,
        }
      }
      categoryNodes.push(...unwrapCategoryTree(node, true))
    }
  }
  categoryNodes.push(nodes)

  return categoryNodes
}

function extractPostIdsFromCategoryTree(
  tree: Array<CategoryTreeNode>
): Array<CategoryTreeNode> {
  let postNodes = []

  for (const node of tree) {
    if (node.type === CategoryTreeNodeType.Category) {
      const nodesInCat = extractPostIdsFromCategoryTree(node.childs)
      postNodes = [...postNodes, ...nodesInCat]
    } else if (node.type === CategoryTreeNodeType.Post) {
      postNodes = [...postNodes, node]
    }
  }

  return postNodes
}

export function findNextPostToComplete(
  tree: Array<CategoryTreeNode>,
  lastCompletedPostId: string,
  completedPostIds: Set<string>
): CategoryTreeNode {
  const posts = extractPostIdsFromCategoryTree(tree)
  const lastCompletedPostIndex = posts.findIndex(
    (node: CategoryTreeNode) => node.id === lastCompletedPostId
  )

  if (lastCompletedPostIndex === -1) {
    return findFirstUncompletedPost(tree, completedPostIds)
  }

  // First find an uncompleted post after the last completed post
  for (let i = lastCompletedPostIndex + 1; i < posts.length; i++) {
    const post = posts[i]
    if (!completedPostIds.has(post.id)) {
      return post
    }
  }

  // If not found above, then find the first uncompleted post before completed one
  for (let i = 0; i < lastCompletedPostIndex; i++) {
    const post = posts[i]
    if (!completedPostIds.has(post.id)) {
      return post
    }
  }
}

// DEPRECATED: Use findNextPostToComplete now
export function findNextPost(
  tree: Array<CategoryTreeNode>,
  postId: string
): CategoryTreeNode | undefined {
  let postMatched = false
  let queue = [...tree]

  while (queue.length > 0) {
    const node = queue.shift()

    if (!node) return

    if (node.type === CategoryTreeNodeType.Post) {
      if (node.id === postId) {
        postMatched = true
      } else if (postMatched) {
        return node
      }
    } else {
      queue = [...queue, ...node.childs]
    }
  }
}

function getNode(categoryNode: any) {
  if (!categoryNode) {
    return null
  }
  const node = categoryNode
  if (node.type === CategoryTreeNodeType.Category) {
    return {
      categoryId: node.id,
      postId: node.childs[node.childs.length - 1]?.id,
    }
  }
  return {
    categoryId: node.node.categoryId,
    postId: node.id,
    contentType: node.node.contentType,
  }
}

function getNextNode(categoryNode: any) {
  if (!categoryNode) {
    return null
  }
  const node = categoryNode
  if (node.type === CategoryTreeNodeType.Category) {
    return {
      categoryId: node.id,
      postId: node.childs[0]?.id,
    }
  }

  return {
    categoryId: node.node.categoryId,
    postId: node.id,
    contentType: node.node.contentType,
  }
}

export function findPreviousPostId(
  categoryTree: Array<CategoryTreeNode>,
  postId: string,
  category: Category,
  playlist: []
) {
  const postIndex = playlist.findIndex(
    (node: CategoryTreeNode) => node.id === postId
  )
  if (postIndex > 0) {
    const data: CategoryTreeNode = playlist[postIndex - 1]
    if (data.type === CategoryTreeNodeType.Post) {
      return getNode(data)
    } else if (data.type === CategoryTreeNodeType.Category) {
      const categoryPost = categoryTree.find(
        (node: CategoryTreeNode) => node.id === data.node.parentCategory
      )
      const category = categoryPost?.childs[postIndex - 1]
      return getNode(category?.childs[category.childs.length - 1])
    }
  }
  let parentCategory
  if (category.parentCategory) {
    parentCategory = categoryTree.find(
      (node: CategoryTreeNode) => node.id === category.parentCategory
    )
    const categoryIndex = parentCategory?.childs.findIndex(
      (node: CategoryTreeNode) => node.id === category.id
    )
    if (categoryIndex > 0) {
      return getNode(parentCategory.childs[categoryIndex - 1])
    }
    const parentCategoryIndex = categoryTree.findIndex(
      (node: CategoryTreeNode) => node.id === category.parentCategory
    )
    if (parentCategoryIndex > 0) {
      const prevCategory = categoryTree[parentCategoryIndex - 1].childs.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
      return getNode(prevCategory[prevCategory.length - 1])
    } else {
      return null
    }
  } else {
    const postCategoryIndex = categoryTree.findIndex(
      (node: CategoryTreeNode) => node.id === category.id
    )
    if (postCategoryIndex > 0) {
      const prevCat = categoryTree[postCategoryIndex - 1].childs.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
      return getNode(prevCat[prevCat.length - 1])
    }
  }
  return null
}

export function findNextPostId(
  categoryTree: Array<CategoryTreeNode>,
  postId: string,
  category: Category,
  playlist: []
) {
  const postIndex = playlist.findIndex(
    (node: CategoryTreeNode) => node.id === postId
  )
  if (postIndex < playlist.length - 1) {
    const data: CategoryTreeNode = playlist[postIndex + 1]
    if (data.type === CategoryTreeNodeType.Post) {
      return getNextNode(data)
    } else if (data.type === CategoryTreeNodeType.Category) {
      const categoryPost = categoryTree.find(
        (node: CategoryTreeNode) => node.id === data.node.parentCategory
      )
      const category = categoryPost?.childs[postIndex + 1]
      return getNextNode(category?.childs[0])
    }
  }
  let parentCategory
  if (category.parentCategory) {
    parentCategory = categoryTree.find(
      (node: CategoryTreeNode) => node.id === category.parentCategory
    )
    const categoryIndex = parentCategory?.childs.findIndex(
      (node: CategoryTreeNode) => node.id === category.id
    )
    if (categoryIndex < parentCategory?.childs.length - 1) {
      return getNextNode(parentCategory.childs[categoryIndex + 1])
    }
    const parentCategoryIndex = categoryTree.findIndex(
      (node: CategoryTreeNode) => node.id === category.parentCategory
    )
    if (parentCategoryIndex < categoryTree.length - 1) {
      const nextCategory = categoryTree[parentCategoryIndex + 1].childs.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
      return getNextNode(nextCategory[0])
    } else {
      return null
    }
  } else {
    const postCategoryIndex = categoryTree.findIndex(
      (node: CategoryTreeNode) => node.id === category.id
    )
    if (postCategoryIndex < categoryTree.length - 1) {
      const nextCat = categoryTree[postCategoryIndex + 1].childs.filter(
        (node: any) =>
          node.type === CategoryTreeNodeType.Post ||
          (node.type === CategoryTreeNodeType.Category && node.node.available)
      )
      return getNextNode(nextCat[0])
    }
  }
  return null
}

export function getCurrentPostIndex(
  postId: string,
  category: Category,
  categoryTree: []
) {
  let index = 0,
    parentCategory: CategoryTreeNode
  if (category?.parentCategory) {
    category?.posts.sort((p1, p2) => p1.sequenceNo - p2.sequenceNo)
    index = category?.posts.findIndex((p) => p.id === postId)
    parentCategory = categoryTree.find(
      (node: CategoryTreeNode) => node.id === category.parentCategory
    )
    const categoryIndex = parentCategory?.childs?.findIndex(
      (node: CategoryTreeNode) => node.id === category.id
    )
    for (let i = 0; i < categoryIndex; i++) {
      const node = parentCategory?.childs[i]
      if (node.type === CategoryTreeNodeType.Category) {
        index += node.childs.length
      } else {
        index++
      }
    }
  } else {
    parentCategory = categoryTree.find(
      (node: CategoryTreeNode) => node.id === category.id
    )
    const postIndex = parentCategory?.childs?.findIndex(
      (node: CategoryTreeNode) => node.id === postId
    )
    for (let i = 0; i < postIndex; i++) {
      const node = parentCategory?.childs[i]
      if (node.type === CategoryTreeNodeType.Category) {
        index += node.childs.length
      } else {
        index++
      }
    }
  }
  return index + 1
}

function removeUnavailableCategories(categoryTree: Array<CategoryTreeNode>) {
  const availableNodes = categoryTree.filter((node: CategoryTreeNode) => {
    if (node.type === CategoryTreeNodeType.Category) {
      const cat = node.node as Category
      return cat.available
    }

    return true
  })

  return availableNodes.map((node: CategoryTreeNode) => {
    if (node.type === CategoryTreeNodeType.Category && node.childs) {
      node.childs = removeUnavailableCategories(node.childs)
    }
    return node
  })
}

export function computeNextPost(
  categoryTree: Array<CategoryTreeNode>,
  postIdsCompleted: Set<string>
): CategoryTreeNode {
  let nextPostToGo = null

  const tree = removeUnavailableCategories([...categoryTree])

  if (postIdsCompleted.size > 0) {
    const lastCompletedPost = findLastCompletedPost(tree, postIdsCompleted)

    // find the next post after the last post that is completed
    if (lastCompletedPost) {
      nextPostToGo = findNextPostToComplete(
        tree,
        lastCompletedPost.id,
        postIdsCompleted
      )
    }
  }

  if (!nextPostToGo) {
    // if there are no completed posts, or the last post in course is completed
    const firstUncompletedPost = findFirstUncompletedPost(
      tree,
      postIdsCompleted
    )
    if (firstUncompletedPost) {
      nextPostToGo = firstUncompletedPost
    } else {
      // Find first post if no incomplete post found
      nextPostToGo = findPost(tree)
    }
  }

  return nextPostToGo || null
}

function findPost(tree: Array<CategoryTreeNode>) {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.type === CategoryTreeNodeType.Post) {
      return node
    } else {
      const post = findPost(node.childs)
      if (post) {
        return post
      }
    }
  }
  return null
}

export function findCategory(tree: Array<CategoryTreeNode>, id: string) {
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.type === CategoryTreeNodeType.Category && node.id === id) {
      return node
    } else {
      const category = findCategory(node.childs, id)
      if (category) {
        return category
      }
    }
  }
  return null
}

export function getPost(categoryTree: Array<CategoryTreeNode>) {
  const tree = removeUnavailableCategories([...categoryTree])
  const post = findPost(tree)
  return post
}

export function getHeroAlignmentClass(textAlignment: string) {
  let alignment = ''

  switch (textAlignment) {
    case HeroTextAlignment.left:
      alignment = 'text-left'
      break
    case HeroTextAlignment.centered:
      alignment = 'text-center'
      break
    case HeroTextAlignment.right:
      alignment = 'text-right'
      break
    default:
      alignment = 'text-center'
  }

  return alignment
}

export function getSections(product: any) {
  if (product && product.customizations && product.customizations.settings) {
    return product.customizations.settings.sections
  }
  return null
}
export function getThemeSettings(product: any) {
  if (product && product.customizations && product.customizations.settings) {
    return product.customizations.settings.themeSettings
  }
  return null
}
export function getProgressBarColor(product: any) {
  let color = '#3b82f6'
  const themeSettings = getThemeSettings(product)
  if (themeSettings) {
    color = themeSettings?.secondaryStyle?.color
  }
  const sections = getSections(product)
  if (sections?.lessonProgress?.progressColor) {
    color = sections.lessonProgress.progressColor
  }
  return color
}

export const getThemeClasses = (value: any, routeName = '') => {
  if (!value) {
    return ''
  }
  const route = useRoute()
  let updateRouteName = routeName ? routeName : route.name.toString()
  if (updateRouteName.endsWith('-v2')) {
    updateRouteName = updateRouteName.slice(0, updateRouteName.length - 3)
  }

  const classes = value[updateRouteName]
  return classes || ''
}

export const getMobileTheme = () => {
  if (store.getters.newMobileScreens) {
    const primary = store.getters.mobileTheme.primary
    const secondary = store.getters.mobileTheme.secondary
    return {
      primary: primary ? 'bg-' + primary : '',
      primaryHexCode:
        store.getters.mobileTheme.primaryHexCode ||
        NEW_MOBILE_UI_DEFAULT_PRIAMRY_COLOR,
      primaryRaw: primary ? primary : '',
      secondaryRaw: secondary ? secondary : '',
      secondary: secondary ? 'bg-' + secondary : '',
      primaryText: primary ? 'text-' + primary : '',
      secondaryText: secondary ? 'text-' + secondary : '',
      secondaryHexCode: store.getters.mobileTheme.secondaryHexCode,
    }
  }
  return {
    primary: '',
    secondary: '',
    primaryText: '',
    secondaryText: '',
  }
}

export function getHeroSpacingClass(spacingConfig: string) {
  let spacing = ''

  switch (spacingConfig) {
    case HeroSpacing.xs:
      spacing = 'py-8'
      break
    case HeroSpacing.small:
      spacing = 'py-12'
      break
    case HeroSpacing.medium:
      spacing = 'py-16'
      break
    case HeroSpacing.large:
      spacing = 'py-24'
      break
    default:
      spacing = 'py-16'
  }

  return spacing
}

export function extractInstructorInfoFromCustomization(
  customization: ProductCustomization
): Instructor {
  const instructor = {} as Instructor

  instructor.heading = customization.instructorHeading
  instructor.name = customization.instructorName
  instructor.title = customization.instructorTitle
  instructor.headshot = customization.instructorHeadshot
  instructor.bio = customization.instructorBio

  return instructor
}

export async function fetchAndSaveBrandData(app) {
  try {
    const hostName = app.$route.query.domain || window.location.hostname
    const savedCookieData = fetchCat()
    const savedLocationId = savedCookieData
      ? savedCookieData.location_id || savedCookieData.locationId
      : ''
    const { location_id: queryLocationId } = app.$route.query

    let locationId

    if (savedLocationId) {
      locationId = savedLocationId
    }

    if (queryLocationId) {
      // more preference to query location id than saved one
      locationId = queryLocationId
    }
    // if (!locationId) {
    //   throw new Error("Couldn't find the location id")
    // }
    if (!hostName || hostName === 'localhost') {
      throw new Error(`Invalid host name ${hostName}`)
    }

    const domainData: any = []
    const brandData = fetchBrandData(app, hostName, locationId)
    domainData.push(brandData)
    const inPortal = window.location.pathname?.startsWith('/courses')
    if (inPortal) {
      const portalSettings = fetchPortalSettings(hostName)
      domainData.push(portalSettings)
    }
    const [brandingData, portalSettingsData] = await Promise.all(domainData)

    if (!brandingData && !portalSettingsData) {
      router.push({ path: '/404' })
    }
    app.$store.commit('SET_CLIENT_PORTAL_DOMAIN_DATA', portalSettingsData)
    return brandingData || {}
  } catch (error) {
    console.error(error)
    return null
  }
}

async function fetchPortalSettings(hostName) {
  try {
    return await restAgent.ClientPortal.getPortalSettings(hostName)
  } catch (error) {
    const { data } = error.response
    if (error.response.status === 404 && data.msg === 'Domain Not Found') {
      console.log('Domain Not Found')
      return null
    }
    return null
  }
}

async function fetchBrandData(app, hostName, locationId) {
  try {
    const { data } = await LoginService.brand({
      domain_name: hostName,
      location_id: locationId,
    })

    if (!data) return null

    const { siteInfo, builderSettings } = data

    if (siteInfo) {
      const featureFlags = await LocationService.getFeatureFlags(
        siteInfo.locationId
      )

      app.$store.commit('SAVE_BRAND_DATA', siteInfo)
      app.$store.commit('SAVE_FEATURE_FLAG', {
        ...featureFlags.data,
        enableNeoTheme: true,
      })
    }

    if (isMobileTablet() && isClientPortal()) {
      return loadDefaultMobileTheme(app)
    }

    if (builderSettings?.appSettings) {
      const { appSettings } = builderSettings
      const primaryColor = appSettings.backgroundColor
      const secondaryColor = appSettings.theme_color

      if (primaryColor) {
        const color = appSettings.color.toLocaleLowerCase().replace(' ', '-')
        app.$store.commit('UPDATE_MOBILE_THEME', {
          primary: 'mobile-' + color + '-primary',
          primaryHexCode: primaryColor,
          secondary: 'mobile-' + color + '-secondary',
          secondaryHexCode: secondaryColor,
        })
      }
      setThemeTokens(primaryColor, secondaryColor, primaryColor)
    } else {
      loadDefaultMobileTheme(app)
    }

    return data
  } catch (error) {
    const { data } = error ? error.response : { data: null }
    if (error.response?.status === 404 && data.msg === 'Domain Not Found') {
      console.log('Domain Not Found')
      return null
    }
    return null
  }
}

function loadDefaultMobileTheme(app: any) {
  app.$store.commit('UPDATE_MOBILE_THEME', {
    primary: 'mobile-default-primary',
    primaryHexCode: NEW_MOBILE_UI_DEFAULT_PRIAMRY_COLOR,
    secondary: 'mobile-default-secondary',
    secondaryHexCode: '#0072ef',
  })
  setThemeTokens()
}

export function getQueryString(search, key) {
  const query = search.slice(search.indexOf('?') + 1, search.length)
  const queryArray = query.split('&')
  for (let i = 0; i < queryArray.length; i++) {
    const pair = queryArray[i].split('=')
    if (pair[0] === key) {
      return decodeURIComponent(pair[1])
    }
  }
  return null
}

export function getHighlightedDescription(description, searchArr, i) {
  try {
    description = removeTags(description)
    if (i == searchArr.length)
      return description.length > 65
        ? `${description.substring(0, 65)}...`
        : description
    const index = description.toLocaleLowerCase().indexOf(searchArr[0])
    if (index === -1) {
      getHighlightedDescription(description, searchArr, i + 1)
    }
    let startIndex = index - 30 > 0 ? index - 30 : 0
    const endIndex =
      index + 30 > description.length ? description.length : index + 30
    if (index + 30 > description.length) {
      startIndex = startIndex - 50
    }
    return (
      description.substring(startIndex, index) +
      description.substring(index, endIndex)
    )
  } catch (e) {
    console.log(e)
    return description
  }
}

function findSubstringInstances(inputString: string, substring: any) {
  return inputString.split(substring)
}

function removeTags(input) {
  return input.replace(/<\/?[^>]+(>|$)/g, '')
}

export function checkNodeIsCompleted(
  allCompletedPosts: Set<string>,
  entity: CategoryTreeNode
) {
  if (entity?.type === CategoryTreeNodeType.Post) {
    return allCompletedPosts.has(entity.id)
  } else {
    const category = entity.node as Category

    if (!category.posts) return false

    const completedPosts = category.posts.filter((p: Post) =>
      allCompletedPosts.has(p.id)
    )

    return completedPosts.length === category.posts.length
  }
}

// export function setFavicon(url: string) {
//   try {
//     if (url) {
//       const link: any =
//         document.querySelector("link[rel*='icon']") ||
//         document.createElement('link')
//       link.type = 'image/x-icon'
//       link.rel = 'shortcut icon'
//       link.href = url
//       document.getElementsByTagName('head')[0].appendChild(link)
//     }
//   } catch (error) {
//     console.error('error while setting favicon --> ', error)
//   }
// }

// export function setPageTitle(title: string) {
//   if (title) {
//     document.title = title
//   }
// }

export function sliceLongText(text: string) {
  return text && text.length > SLICED_TITLE_LENGTH
    ? `${text.slice(0, SLICED_TITLE_LENGTH)}...`
    : text
}

export const getFileType = (filename: string): string => {
  const parts = filename.split('.')
  const extension = parts[parts.length - 1]
  return extension
}

export const acceptableFile = (file: any): boolean => {
  if (!file) return false

  const fileType = file.type

  const fileName = file.name
  const typeFromName = getFileType(fileName)
  if (
    nonAcceptableFileTypes.has(fileType) ||
    nonAcceptableFileTypes.has(typeFromName)
  ) {
    return false
  }

  return true
}
export function getOptionAlphabet(sequenceNumber: number) {
  const baseValue = 96
  return String.fromCharCode(baseValue + sequenceNumber).toUpperCase()
}

export function isAppInstalled() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches
  if (document.referrer.startsWith('android-app://')) {
    return true
  } else if ((window.navigator as any).standalone || isStandalone) {
    return true
  }
  return false
}

export function notify(
  notification: any,
  {
    type,
    title,
    description,
    time,
  }: {
    type: string
    title: string
    description?: string
    time?: number
  }
) {
  notification[type]({
    content: title,
    meta: description,
    duration: time ? time : 5000,
    keepAliveOnHover: true,
  })
}

export const extractAPIErrorMsg = (error) => {
  let errorMsg = error?.response?.data?.message
  if (Array.isArray(errorMsg)) errorMsg = errorMsg.join(', ')
  if (errorMsg?.includes('FDS'))
    errorMsg =
      'Your transaction has been declined. Please contact the business owner'
  return errorMsg
}
export function redirectTo(path: string, query?: string, newTab = false) {
  const newURL = `${window.location.origin}/${path}?${query ? query : ''}`
  if (newTab) {
    window.open(newURL, '_blank')
  } else {
    window.location.href = newURL
  }
}

export const formatNameForAvatar = (name: string) => {
  const nameArr = name?.split(' ')
  return nameArr
    ?.map((item) => item[0])
    .join('')
    .toUpperCase()
}

export const capitalizeFirstLetterName = (name: string) => {
  return name
    .split(' ')
    .map((item) => `${item[0]?.toUpperCase()}${item?.slice(1)}`)
    .join(' ')
}

export function isClientPortal() {
  const clientPortalPrefixPath = '/courses'
  return window.location.pathname.startsWith(clientPortalPrefixPath)
}

export function redirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (to.path.endsWith('/404')) return next()

  // const cpLandingPageRoutes = ['LandingPage', 'LandingPage-v2']
  const routeVersionSuffix = '-v2'
  const isLoginRoute = to.name === 'login'

  const clientPortalPrefixPath = '/courses'
  // cpLandingPageRoutes.includes(String(to.name))
  //   ? ''
  //   : '/courses'
  if (isClientPortal() && !to.fullPath.startsWith(clientPortalPrefixPath)) {
    const updatedParams: RouteLocationNormalized = {
      ...to,
      fullPath: !isLoginRoute
        ? clientPortalPrefixPath + to.fullPath
        : to.fullPath,
      path: !isLoginRoute ? clientPortalPrefixPath + to.path : to.path,
      name: String(to.name) + routeVersionSuffix,
    }
    return next(updatedParams)
  } else {
    return next()
  }
}

export const getValidUrl = (url = '') => {
  let newUrl = decodeURIComponent(url)
  newUrl = newUrl.trim().replace(/\s/g, '')
  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`
  }
  return newUrl
}

function stripHtmlTagsOutsideStrings(code: string) {
  // This pattern finds strings in the code
  const stringPattern = /(['"`])(?:(?!\1)[^\\]|\\.)*?\1/g

  // Split the code based on the string pattern
  const segments = code.split(stringPattern)

  // Process segments to remove HTML tags but preserve < and > operators
  for (let i = 0; i < segments.length; i += 2) {
    // Only remove valid HTML tags, preserve < and > used in operators
    segments[i] = segments[i].replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, '')
  }

  // Recombine segments, preserving strings intact
  return segments
    .map(
      (segment, index) =>
        index % 2 === 0 ? segment : stringPattern.exec(code)[0] // Keep original strings
    )
    .join('')
}
function replaceDOMLoadWithCustomEvent(customCode: string) {
  // Replace DOMContentLoaded with a custom event (e.g., "customDOMContentLoaded")
  // Replacing the event with a custom one allows us to manually trigger it.
  return customCode.replace(/DOMContentLoaded/g, 'customDOMContentLoaded')
}

export function sanitizeJavaScriptCodeToExecuteIt(
  jsCode: string | null | undefined = '',
  extraParams?: Array<{ type: string; value: string }>
): { tag: HTMLElement; trigger: () => void } {
  // Remove HTML tags and leading/trailing white spaces
  if (!jsCode || !jsCode.trim())
    return { tag: createElement('script', []), trigger: () => ({}) }

  const customCode = stripHtmlTagsOutsideStrings(jsCode)
  const modifiedCustomCode = replaceDOMLoadWithCustomEvent(customCode)
  // Add a script tag with the custom code
  const id = 'custom-code-' + new Date().valueOf()

  const attributes = [{ type: 'id', value: id }]
  if (extraParams) {
    attributes.push(...extraParams)
  }

  const scriptTag = createElement('script', attributes, modifiedCustomCode)
  return {
    tag: scriptTag,
    trigger: () => {
      const event = new Event('customDOMContentLoaded')
      const script = document.getElementById(id)
      if (script) {
        document.dispatchEvent(event) // Dispatch event for the specific script
      }
    },
  }
}

export function sanitizeJavaScriptCode(jsCode: string | null | undefined = '') {
  // Remove HTML tags and leading/trailing white spaces
  return jsCode?.replace(/<\/?[^>]+(>|$)/g, '').trim()
}

export function enableAnalytics(contactId: string) {
  const gtagId = isClientPortal() ? CLIENTPORTAL_GTAGID : MEMBERSHIP_GTAGID
  setOptions({
    appName: 'Courses',
    config: {
      id: gtagId,
      params: {
        send_page_view: true,
        user_id: contactId,
      },
    },
  })

  bootstrap()
  event('custom-login-event', {
    appName: isClientPortal() ? 'Client Portal' : 'Legacy Membership',
    user_id: contactId,
  })
}

export function createElement(
  type: string,
  attributes: Array<any>,
  innerHTML = ''
) {
  const ele = document.createElement(type)
  attributes.forEach((attr: { type: string; value: any }) => {
    ele.setAttribute(attr.type, attr.value)
  })
  ele.innerHTML = innerHTML
  return ele
}

export function getDefaultPosterImage(contentType: PostContentType) {
  return [PostContentType.quiz, PostContentType.assignment].includes(
    contentType
  )
    ? defaultAssessmentImage
    : defaultPosterImage
}

export function extractVideoTypeFromUrl(url: string) {
  if (!url) return videoFileTypeMapping.mp4
  const splittedUrl = new URL(url).pathname.split('/')
  const filename = splittedUrl[splittedUrl.length - 1]
  return videoFileTypeMapping[getFileType(filename)]
    ? videoFileTypeMapping[getFileType(filename)]
    : videoFileTypeMapping.mp4
}

export function detectDevice() {
  let deviceName = ''
  if (
    window.navigator.userAgent.toLocaleLowerCase().includes('iphone') ||
    window.navigator.userAgent.toLocaleLowerCase().includes('ipad')
  ) {
    deviceName = 'iphone'
  } else if (
    window.navigator.userAgent.toLocaleLowerCase().includes('android')
  ) {
    deviceName = 'android'
  }

  return deviceName
}
export function detectBrowser() {
  let browserName = ''
  if (
    window.navigator.userAgent.toLocaleLowerCase().includes('chrome') ||
    window.navigator.userAgent.toLocaleLowerCase().includes('crios')
  ) {
    browserName = 'chrome'
  } else if (
    window.navigator.userAgent.toLocaleLowerCase().includes('safari')
  ) {
    browserName = 'safari'
  }

  return browserName
}

export function addNewLineToLongString(
  str: string,
  maxLength = MAX_COMMENT_LENGTH
) {
  const strArray = str.split('<p>')
  let newStr = ''

  strArray.forEach((s: string) => {
    while (s.length > maxLength) {
      let breakPoint = maxLength

      // Ensure the break point is at the end of a word
      if (s[breakPoint] !== ' ' && s[breakPoint - 1] !== ' ') {
        breakPoint = s.lastIndexOf(' ', breakPoint)
        if (breakPoint === -1) breakPoint = maxLength // Fallback if no space is found
      }

      newStr += s.substring(0, breakPoint) + '<br/>'
      s = s.substring(breakPoint).trim()
    }
    newStr += s
  })

  return newStr
}

export function getTextContentFromHTML(htmlContent: string) {
  if (!htmlContent) return ''
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html')
  if (doc.body && doc.body.textContent) {
    return doc.body.textContent
  }

  return ''
}

const categoryAndPostsLookup = new Map<string, any>()
export function getCategoryAndPostsLookup(categoryTree: CategoryTreeNode[]) {
  categoryTree.forEach((category) => {
    if (category.childs.length)
      category.childs.forEach((post) => {
        categoryAndPostsLookup.set(post.id, post)
        if (post.childs && post.childs.length) {
          return getCategoryAndPostsLookup(post.childs)
        }
      })
    categoryAndPostsLookup.set(category.id, category)
  })
  return categoryAndPostsLookup
}

export function getFileSize(size: number) {
  const sizeConstant = 1024 * 1024
  if (size > sizeConstant) return `${(size / sizeConstant).toFixed(2)} MB`
  return `${(size / 1024).toFixed(2)} KB`
}

function setThemeTokens(
  primary = NEW_MOBILE_UI_DEFAULT_PRIAMRY_COLOR,
  secondary = NEW_MOBILE_UI_DEFAULT_SECONDARY_COLOR,
  accent = NEW_MOBILE_UI_DEFAULT_ACCENT_COLOR
) {
  document.documentElement.style.setProperty('--primary-color', primary)
  document.documentElement.style.setProperty('--accent-color', accent)
  document.documentElement.style.setProperty('--secondary-color', secondary)
}

export function extractTextFromHTML(htmlString) {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString

  return tempDiv.textContent || tempDiv.innerText || ''
}

export function renderFileTypeIcon(filename) {
  const extension = filename.split('.').pop().toLowerCase()

  const icons = {
    pdf: newPdfLogo,
    doc: newDocIcon,
    docx: newDocIcon,
    xls: xlsxIcon,
    xlsx: xlsxIcon,
    jpg: jpgIcon,
    jpeg: jpegIcon,
    png: pngIcon,
    gif: jpgIcon,
    mp4: mp4Icon,
    mp3: mp3Icon,
    zip: defaultIcon,
    txt: txtIcon,
    default: defaultIcon, // Default icon for unknown extensions
  }

  return icons[extension] || icons['default']
}

export const inIframe = () => window.self !== window.top
