import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { UIModal, UIModalHeader, UIModalFooter } from '@gohighlevel/ghl-ui'

import { AlertCircleIcon } from '@gohighlevel/ghl-icons/24/outline'


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentFailedModal',
  props: {
  show: Boolean,
},
  emits: [
  'update:confirmed',
  'update:show',
  'redirect-to-current',
],
  setup(__props, { emit: __emit }) {

const { t } = useI18n()

const props = __props
const emit = __emit

const showModal = ref(false)
onMounted(() => (showModal.value = props.show))
watch(
  () => props.show,
  (showModalVal: boolean) => (showModal.value = showModalVal)
)

function closeModal() {
  if (isPaymentProcessing.value) {
    return false
  }
  emit('update:show', false)
  showModal.value = false
}

function redirectPage() {
  emit('redirect-to-current')
}
const isPaymentProcessing = ref(false)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(UIModal), {
    width: 300,
    autoFocus: false,
    maskClosable: true,
    show: showModal.value,
    className: "fixed top-80 left-0 right-0 flex justify-center"
  }, {
    header: _withCtx(() => [
      _createVNode(_unref(UIModalHeader), {
        type: 'error',
        title: _unref(t)('payment.failed.title'),
        description: _unref(t)('payment.failed.description'),
        icon: _unref(AlertCircleIcon),
        onClose: closeModal,
        id: "modal-header"
      }, null, 8, ["title", "description", "icon"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_unref(UIModalFooter), {
        positiveText: _unref(t)('common.button.cancel'),
        onPositiveClick: redirectPage,
        loading: false,
        disabled: false,
        id: "modal-footer",
        type: 'error',
        disableNegativeBtn: false
      }, null, 8, ["positiveText"])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})